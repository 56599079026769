import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Main, PageLoader } from "components";
import { Field, reduxForm } from "redux-form";
import { Button, Input } from "components";
import { editUser, getUser } from "actions/users";

const InputField = props => <Input {...props} />;

class UsersEdit extends PureComponent {
  componentDidMount() {
    const {
      getUser,
      match: {
        params: { id },
      },
    } = this.props;
    getUser(id);
  }

  onSubmit = values => {
    const { user, change, editUser } = this.props;
    editUser(user.data.id, values).then(() => change("password", ""));
  };

  render() {
    const { user, pristine, history } = this.props;

    if (!user || user.status === "invalid" || user.status === "loading") return <PageLoader />;

    if (user.status === "failure") {
      return <p>Error</p>;
    }

    const { handleSubmit } = this.props;
    return (
      <Main breadcrumbs={[{ name: user?.data?.name }]} title={user?.data?.name}>
        <div className="dashboard-wrapper-flex">
          <div className="column-half">
            <div className="dashboard">
              <header className="dashboard__header">
                <div className="icon__container">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                    <rect fill="none" width="20" height="20" />
                    <circle
                      fill="#494b74"
                      opacity="0.3"
                      cx="8.333"
                      cy="8.333"
                      r="8.333"
                      transform="translate(1.667 1.667)"
                    />
                    <rect fill="#3699ff" width="1.667" height="5.833" rx="0.833" transform="translate(9.167 8.333)" />
                    <rect fill="#494b74" width="1.667" height="1.667" rx="0.833" transform="translate(9.167 5.833)" />
                  </svg>
                </div>
                <h2 className="dashboard__title heading">Podstawowe informacje</h2>
                <Button type="cancel" text="Anuluj" onClick={() => history.push("/users")} />
                <Button
                  type="save"
                  disabled={pristine}
                  style={{ marginLeft: "15px" }}
                  text="Zapisz"
                  onClick={handleSubmit(this.onSubmit)}
                />
              </header>
              <div className="dashboard__form">
                <form noValidate onSubmit={handleSubmit(this.onSubmit)}>
                  <div className="form-row">
                    <Field label="Imie i nazwisko*" name="name" component={InputField} />
                  </div>
                  <div className="form-row">
                    <Field label="Adres e-mail*" name="email" component={InputField} />
                  </div>
                  <div className="form-row">
                    <Field label="Utwórz nowe hasło*" name="password" component={InputField} />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Main>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  user,
  initialValues: { name: user?.data?.name, email: user?.data?.email },
});

const mapDispatchToProps = dispatch => ({
  editUser: (data, id) => dispatch(editUser(data, id)),
  getUser: id => dispatch(getUser(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "users_edit", enableReinitialize: true })(withRouter(UsersEdit)));
