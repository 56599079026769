import React from 'react'

const New = ({ addText, handleOpenEditModal }) => {
    return (
        <div className="image-uploader__box new" onClick={handleOpenEditModal}>
            <div className="inner">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10.735"
                    height="10.735"
                    viewBox="0 0 10.735 10.735">
                    <g transform="translate(0.5 0.5)">
                        <g transform="translate(4.867) rotate(45)">
                            <line
                                stroke="#a2a5b9"
                                y2="9.735"
                                transform="translate(6.884 0) rotate(45)"
                            />
                            <line
                                stroke="#a2a5b9"
                                y2="9.735"
                                transform="translate(6.884 6.884) rotate(135)"
                            />
                        </g>
                    </g>
                </svg>
                <p>{addText}</p>
            </div>
        </div>
    )
}

export default New