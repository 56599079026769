import React from "react";

export default function Users() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            {/* <defs><style>.a{fill:none;fill-rule:evenodd;}.b,.c{fill:#494b74;}.b{opacity:0.3;}</style></defs> */}
            <path fill='none' fillRule='evenodd' d="M0,0H20V20H0Z" />
            <path fill='#494b74' opacity='0.3' d="M11.342,9.684a3.342,3.342,0,1,1,3.342-3.342A3.342,3.342,0,0,1,11.342,9.684Z" transform="translate(-1.342 -0.493)" />
            <path fill='#494b74' d="M3,19.015C3.323,15.027,6.548,13,10.48,13c3.986,0,7.262,1.916,7.506,6.016a.573.573,0,0,1-.625.668H3.606A.924.924,0,0,1,3,19.015Z" transform="translate(-0.493 -2.191)" />
        </svg>
    );

}