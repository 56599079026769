import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import { reducer as toastr } from "react-redux-toastr";
import users from "./users";
import user from "./user";
import sections from "./sections";
import section from "./section";
import static_pages from "./static_pages";
import static_page from "./static_page";
import actors_categories from "./actors_categories";
import actors from "./actors";
import actor from "./actor";
import movies_categories from "./movies_categories";
import movies_category from "./movies_category";
import movies from "./movies";
import movie from "./movie";
import sidebar from "./sidebar";
import comments from "./comments";
import questions from "./questions";
import movie_category_movies from "./movie_category_movies";

export default combineReducers({
  form,
  sidebar,
  toastr,
  users,
  user,
  sections,
  section,
  static_pages,
  static_page,
  actors_categories,
  actors,
  actor,
  movies,
  movies_categories,
  movie_category_movies,
  movies_category,
  movie,
  comments,
  questions,
});
