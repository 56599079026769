import React from "react";
export const convertDate = ({
  value = null,
  seperator = "/",
  reverse = false,
  with_time = true,
  time_color = "#BBBDC5",
}) => {
  if (!value) return "-";
  try {
    let date = value.split("T")[0].split("-");
    if (reverse) {
      date = date.reverse();
    }
    date = date.join(seperator);
    const time = value
      .split("T")[1]
      .split(":")
      .slice(0, 2)
      .join(":");
    return (
      <>
        <span>{date}</span> <span style={time_color ? { color: time_color } : {}}>{time}</span>
      </>
    );
  } catch (error) {
    console.log(error);
    return value;
  }
};

export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

const buildFormData = (formData, data, parentKey) => {
  if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? "" : data;

    formData.append(parentKey, value);
  }
};

export const jsonToFormData = data => {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
};


export const handleErrors = data => {
  const errors = {};
  try {
    data.map(({ detail, source: { pointer } }) => (errors[pointer.split("/")[pointer.split("/").length - 1]] = detail));
    return errors;
  } catch (error) {
    console.log(error);
    return [];
  }
};