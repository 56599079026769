import { toastr } from "react-redux-toastr";
import { client, apiFormatter } from "utils/api";

export const getMovies = (page = 1, per_page = 10, query = null, loading = true) => async dispatch => {
  loading && dispatch({ type: "MOVIES_LOADING" });
  await client
    .get(
      `videos?include=category_joins.category&sort[order]=asc&sort[column]=id&page=${page}&per_page=${per_page}${
        query ? `&q[name_matches]=%25${query}%25` : ""
      }`
    )
    .then(({ data }) => {
      dispatch({
        type: "MOVIES_SUCCESS",
        data: apiFormatter(data),
        meta: data.meta,
      });
    })
    .catch(() => {
      dispatch({ type: "MOVIES_FAILURE" });
      toastr.error("Błąd", "Wystąpił błąd podzczas pobierania");
    });
};

export const getMovie = id => async dispatch => {
  try {
    dispatch({ type: "MOVIE_LOADING" });
    const movie = await client.get(
      `videos/${id}?include=source,form,actor_video.actor,video_hero.video,category_joins.category,seo_content`
    );
    const movie_data = apiFormatter(movie.data);
    if (!!!movie_data.form) {
      const form = await client.post(`videos/${movie_data.id}/forms`);
      const form_data = apiFormatter(form.data);
      movie_data.form = form_data;
    }
    dispatch({
      type: "MOVIE_SUCCESS",
      data: movie_data,
    });
  } catch (error) {
    dispatch({ type: "MOVIE_FAILURE" });
    toastr.error("Błąd", "Wystąpił błąd podzczas pobierania");
  }
};

export const deleteMovie = (id, data) => dispatch =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/videos/${id}`);
      toastr.success("Sukces", "Film został usunięty");
      resolve();
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      reject();
    }
  });

export const addMovie = data => dispatch =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await client.post(`/videos?include=source,video_hero.video,category_joins.category,seo_content`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toastr.success("Sukces", "Film został dodany");
      resolve(res?.data?.data?.id);
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      reject();
    }
  });

export const editMovie = (id, data) => dispatch =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await client.put(
        `/videos/${id}?include=source,actor_video.actor,video_hero.video,category_joins.category,seo_content`,
        data
      );
      toastr.success("Sukces", "Film został zaktualizowany");
      dispatch({
        type: "MOVIE_SUCCESS",
        data: apiFormatter(res.data),
      });
      resolve(apiFormatter(res.data));
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      reject();
    }
  });
