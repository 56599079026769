import React, { useState } from "react";
import { Field } from "redux-form";
import MarkdownEditor from "react-markdown-editor-lite";
import { Input } from "components";
const Editor = ({ value, name, label, change }) => {
  const [text, setText] = useState(value || "");

  return (
    <div className="text-editor md">
      <Field
        component={(props) => <Input {...props} />}
        type="hidden"
        name={name}
      />
      <p className="text-editor-label">{label}</p>
      <MarkdownEditor
        plugins={["header", "fonts", "link"]}
        value={text}
        config={{
          view: {
            menu: true,
            md: true,
            html: false,
            fullScreen: false,
            hideMenu: false,
          },
        }}
        style={{ height: "280px" }}
        onChange={({ text }) => {
          change(name, text);
          setText(text);
        }}
      />
    </div>
  );
};

export default Editor;
