import React from "react";

export default function Static() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <rect fill="none" width="20" height="20" />
      <rect
        fill="#494b74"
        width="16"
        height="4"
        rx="1"
        transform="translate(2 3)"
      />
      <rect
        fill="#494b74"
        opacity="0.3"
        width="16"
        height="9"
        rx="1"
        transform="translate(2 9)"
      />
    </svg>
  );
}
