import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import {Main, PageLoader} from "components";
import {getActor} from "actions/actors";
import {BasicData, Movies} from "./components";

class ActorEdit extends PureComponent {
  componentDidMount() {
    this.getActor();
  }

  getActor() {
    const {
      match: {
        params: {id},
      },
    } = this.props;
    this.props.getActor(id);
  }

  render() {
    const {
      actor,
      match: {
        params: {type},
      },
    } = this.props;

    if (!actor || actor.status === "invalid" || actor.status === "loading")
      return <PageLoader />;

    if (actor.status === "failure") {
      return <p>Error</p>;
    }

    return (
      <Main
        breadcrumbs={[{name: "Bohaterowie"}, {name: actor.data.name}]}
        title={actor.data.name}
      >
        <ul className="dashboard__tabs">
          <li
            className={`tab dashboard-tab-text ${type === "basic_info" &&
              "active"}`}
          >
            <Link to="basic_info">Podstawowe informacje</Link>
          </li>
          <li
            className={`tab dashboard-tab-text ${type === "movies" &&
              "active"}`}
          >
            <Link to="movies">Filmy</Link>
          </li>
        </ul>
        {type === "basic_info" ? <BasicData /> : <Movies />}
      </Main>
    );
  }
}

const mapStateToProps = ({actor}) => ({
  actor,
});

const mapDispatchToProps = (dispatch) => ({
  getActor: (id) => dispatch(getActor(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ActorEdit));
