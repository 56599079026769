import { toastr } from "react-redux-toastr";
import { client, apiFormatter } from "utils/api";

export const getComments = (video_id, page = 1, per_page = 10) => async dispatch => {
  try {
    dispatch({ type: "COMMENTS_LOADING" });
    const { data } = await client.get(
      `videos/${video_id}/comments?sort[order]=asc&sort[column]=id&page=${page}&per_page=${per_page}&include=user`
    );
    dispatch({
      type: "COMMENTS_SUCCESS",
      data: apiFormatter(data),
      meta: data.meta,
    });
  } catch (error) {
    dispatch({ type: "COMMENTS_FAILURE" });
    toastr.error("Błąd", "Wystąpił błąd podzczas pobierania");
  }
};

export const deleteComment = id =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`comments/${id}`);
      toastr.success("Sukces", "Komentarz został usunięty");
      resolve();
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      reject();
    }
  });
