import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { Main, Button, Input, Select } from "components";
import { addSection } from "actions/sections";

const series_options = [
  { label: 'Pierwsza', value: 'first' }, 
  { label: 'Druga', value: 'second' },
  { label: 'Trzecia', value: 'third' },
  { label: 'Czwarta', value: 'fourth' },
]

const InputField = (props) => <Input {...props} />;

class HomeNew extends PureComponent {
  onSubmit = (values) => {
    const { history, addSection } = this.props;
    addSection(values).then((id) => id && history.push(`/home_page/${id}`));
  };

  render() {
    const { handleSubmit, history, change, initialValues } = this.props;
    return (
      <Main breadcrumbs={[{ name: "Dodaj sekcje" }]} title="Dodaj sekcje">
        <div className="dashboard-wrapper-flex">
          <div className="column-half">
            <div className="dashboard">
              <header className="dashboard__header">
                <h2 className="dashboard__title heading">
                  Podstawowe informacje
                </h2>
                <Button
                  type="cancel"
                  text="Anuluj"
                  onClick={() => history.push("/home_page")}
                />
                <Button
                  type="save"
                  onClick={handleSubmit(this.onSubmit)}
                  style={{ marginLeft: "15px" }}
                  text="Zapisz"
                />
              </header>
              <div className="dashboard__form">
                <form noValidate>
                  <div className="form-row">
                    <Field
                      label="Nazwa sekcji*"
                      name="name"
                      component={InputField}
                    />
                    <Field type="hidden" name="series" component={InputField} />
                    <Select
                      label="Seria"
                      name="series"
                      defaultValue={series_options.find(({ value }) => value === initialValues?.series)}
                      options={series_options}
                      handleChange={(value) => change('series', value)}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Main>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addSection: (id) => dispatch(addSection(id)),
});

export default connect(
  () => ({ initialValues: { series: 'first' } }),
  mapDispatchToProps
)(reduxForm({ form: "section", enableReinitialize: true })(withRouter(HomeNew)));
