import React, {useState} from "react";
import {Field} from "redux-form";
import MarkdownEditor from "react-markdown-editor-lite";
import {Input} from "components";
const Editor = ({value, name, label, update_at, change}) => {
  const [text, setText] = useState(value || "");

  return (
    <div className="dashboard">
      <header className="dashboard__header">
        <div className="icon__container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          >
            <rect fill="none" width="20" height="20" />
            <path
              fill="#3699ff"
              d="M0,13.262V3.306a1.25,1.25,0,0,1,.375-.893L2.471.357A1.25,1.25,0,0,1,4.208.344l2.07,1.969a1.25,1.25,0,0,1,.389.906V13.262a1.25,1.25,0,0,1-1.25,1.25H1.25A1.25,1.25,0,0,1,0,13.262Z"
              transform="translate(7.226 16.411) rotate(-135)"
            />
            <rect
              fill="#3699ff"
              opacity="0.3"
              width="13"
              height="1"
              rx="0.5"
              transform="translate(4 17)"
            />
          </svg>
        </div>
        <h2 className="dashboard__title heading editor">Edytor tekstu</h2>
    
          <span className="actualize">
              {update_at && "Ostatnia aktualizacja: 04-06-2020, 16:37"}
          </span>
      </header>
      <div className="text-editor">
        <Field
          component={(props) => <Input {...props} />}
          type="hidden"
          name={name}
        />
        <p className="text-editor-label">{label}</p>
        <MarkdownEditor
          plugins={["header", "fonts", "link"]}
          value={text}
          config={{
            view: {
              menu: true,
              md: true,
              html: false,
              fullScreen: false,
              hideMenu: false,
            },
          }}
          style={{height: "280px"}}
          onChange={({text}) => {
            change(name, text);
            setText(text);
          }}
        />
      </div>
    </div>
  );
};

export default Editor;
