import { toastr } from "react-redux-toastr";
import { client } from "utils/api";

export const clearCache = async () => {
  try {
    await client.post("/flush_caches");
    toastr.success("Sukces", "Cache zostały usunięte!");
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd");
  }
};
