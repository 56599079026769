const validate = ({ content, question_options_attributes }) => {
  const errors = { question_options_attributes: [{}, {}, {}, {}] };

  if (!content || content.length < 1) {
    errors.content = "Pole wymagane";
  }

  Array(4)
    .fill(null)
    .forEach((el, index) => {
      if (
        !question_options_attributes ||
        !question_options_attributes[index] ||
        !question_options_attributes[index].content ||
        question_options_attributes[index].content.length < 1
      ) {
        errors.question_options_attributes[index].content = "Pole wymagane";
      }
    });

  return errors;
};

export default validate;
