import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {handleLogin} from "actions/auth";
import Logo from "../images/icons/logo.png";

class Login extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: null,
    };
  }

  render() {
    return (
      <div className="login-container">
        <div className="login-wrapper">
          <header className="login-header">
            <img src={Logo} alt="Logo"/>
          </header>
          <div className="login-panel">
            <form
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                this.props.handleLogin({user: this.state});
              }}
            >
              <div className="form-group">
                <div className="inp-wrapper">
                  <label htmlFor="email">Adres E-mail</label>
                  <div className="inp-group">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      onChange={({target: {value}}) =>
                        this.setState({email: value})
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="inp-wrapper">
                  <label htmlFor="password">Hasło</label>
                  <div className="inp-group">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      onChange={({target: {value}}) =>
                        this.setState({password: value})
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="login-submit">
                <button className="btn btn-lg btn-green" type="submit">
                  <span>Zaloguj</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  handleLogin: (val) => dispatch(handleLogin(val)),
});

export default connect(null, mapDispatchToProps)(Login);
