import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import queryString from "query-string";
import {Search, Pagination} from "components";
import {Button} from "components";
import {Main, PageLoader} from "components";
import ListItem from "./components/ListItem";
import {
  getMoviesCategories,
  deleteMoviesCategory,
} from "actions/movies_categories";

class MoviesCategoriesList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      query: queryString.parse(this.props.location.search).q,
    };
  }

  onSubmit = (values) => {
    console.log(values);
  };

  getMoviesCategories = () => {
    const {
      location: {search},
      getMoviesCategories,
    } = this.props;
    const {page, per_page, q} = queryString.parse(search);
    getMoviesCategories(page, per_page, q);
  };

  componentDidMount() {
    this.getMoviesCategories();
  }

  handleDelete = (id) => {
    this.props
      .deleteMoviesCategory(id)
      .then(() => {
        this.props.getMoviesCategories();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const {
      movies_categories,
      getMoviesCategories,
      history,
      location,
    } = this.props;
    const {query} = this.state;

    if (
      !movies_categories ||
      movies_categories.status === "invalid" ||
      movies_categories.status === "loading"
    )
      return <PageLoader />;

    if (movies_categories.status === "failure") {
      return <p>Error</p>;
    }
    const {data, meta} = movies_categories;
    return (
      <Main
        breadcrumbs={[{name: "Kategorie filmowe"}]}
        title="Kategorie filmowe"
      >
        <div className="dashboard">
          <header className="dashboard__header">
            <div className="icon__container">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
              >
                <rect fill="none" width="20" height="20" />
                <rect fill="none" width="20" height="20" />
                <path
                  fill="#494b74"
                  fillRule="evenodd"
                  opacity="0.3"
                  d="M4.833,5H16.5a.833.833,0,0,1,0,1.667H4.833A.833.833,0,0,1,4.833,5Zm0,6.667H16.5a.833.833,0,0,1,0,1.667H4.833a.833.833,0,0,1,0-1.667Z"
                  transform="translate(-0.667 -0.833)"
                />
                <path
                  fill="#494b74"
                  fillRule="evenodd"
                  d="M4.833,9H16.5a.833.833,0,0,1,0,1.667H4.833A.833.833,0,0,1,4.833,9Zm0,6.667H16.5a.833.833,0,0,1,0,1.667H4.833a.833.833,0,0,1,0-1.667Z"
                  transform="translate(-0.667 -1.5)"
                />
              </svg>
            </div>
            <h2 className="dashboard__title heading">Lista kategorii</h2>
            <Button
              type="add"
              text="Dodaj kategorie"
              onClick={() => history.push("/movies_categories/new")}
            />
          </header>
          <Search
            action={(page, per_page, query, loading) => {
              this.setState({query});
              getMoviesCategories(page, per_page, query, loading);
            }}
            defaultValue={query}
          />
          {data?.length > 0 ? (
            <>
              <table className="table">
                <thead className="table__header table-head">
                  <tr>
                    <th>Nazwa kategorii</th>
                    <th width="200">liczba filmów</th>
                    <th width="200">Działania</th>
                  </tr>
                </thead>
                <tbody className="table__body table-body">
                  {data.map((props) => (
                    <ListItem
                      key={props.id}
                      handleDelete={(id) => this.handleDelete(id)}
                      {...props}
                    />
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <div style={{padding: 20}}>
              <p>Brak Kategorii Filmowych</p>
            </div>
          )}
          <Pagination
            data={{...meta, query}}
            history={history}
            location={location}
            action={(page, per_page) =>
              getMoviesCategories(page, per_page, query)
            }
          />
        </div>
      </Main>
    );
  }
}

const mapStateToProps = ({movies_categories}) => ({movies_categories});

const mapDispatchToProps = (dispatch) => ({
  deleteMoviesCategory: (id) => dispatch(deleteMoviesCategory(id)),
  getMoviesCategories: (page, per_page, query, loading) =>
    dispatch(getMoviesCategories(page, per_page, query, loading)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MoviesCategoriesList));
