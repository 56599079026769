import React from "react";

const Edit = ({
    path,
    handleOpenDeleteModal,
    handleOpenEditModal,
    showDelete,
    showEdit,
    url,
}) => {
    return (
        <div className="image-uploader__box">
            <img src={`${url}${path}`} alt="" />
            {!showDelete && !showEdit ? null : (
                <div className="image-uploader__box__overlay">
                    {showEdit && (
                        <button
                            type="button"
                            className="btn btn-icon btn-edit btn-sm"
                            onClick={handleOpenEditModal}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                            >
                                <rect fill="none" width="20" height="20" />
                                <path
                                    fill="#fff"
                                    d="M.216,13.775,0,3.13a1.25,1.25,0,0,1,.347-.89L2.5,0,4.652,2.249A1.25,1.25,0,0,1,5,3.118L4.97,13.754A1.25,1.25,0,0,1,3.72,15H1.466A1.25,1.25,0,0,1,.216,13.775Z"
                                    transform="translate(8.536 15.677) rotate(-135)"
                                />
                                <path
                                    className="#fff"
                                    opacity="0.3"
                                    d="M11.083,2a.833.833,0,1,1,0,1.667H5.333A1.667,1.667,0,0,0,3.667,5.333v10A1.667,1.667,0,0,0,5.333,17h10A1.667,1.667,0,0,0,17,15.333V11.167a.833.833,0,1,1,1.667,0v4.167a3.333,3.333,0,0,1-3.333,3.333h-10A3.333,3.333,0,0,1,2,15.333v-10A3.333,3.333,0,0,1,5.333,2Z"
                                    transform="translate(-0.333 -0.465)"
                                />
                            </svg>
                            <span>edytuj</span>
                        </button>
                    )}
                    {showDelete && (
                        <button
                            type="button"
                            className="btn btn-icon btn-delete btn-sm"
                            onClick={handleOpenDeleteModal}
                        >
                            <svg
                                id="Group_377"
                                data-name="Group 377"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                            >
                                <rect
                                    id="Rectangle_378"
                                    data-name="Rectangle 378"
                                    width="24"
                                    height="24"
                                    fill="none"
                                ></rect>
                                <path
                                    id="Path_227"
                                    data-name="Path 227"
                                    d="M6,8H18l-.893,11.615A1.5,1.5,0,0,1,15.611,21H8.389a1.5,1.5,0,0,1-1.5-1.385Z"
                                    fill="white"
                                ></path>
                                <path
                                    id="Path_228"
                                    data-name="Path 228"
                                    d="M14,4.5v-1a.5.5,0,0,0-.5-.5h-3a.5.5,0,0,0-.5.5v1H5.5A.5.5,0,0,0,5,5v.5a.5.5,0,0,0,.5.5h13a.5.5,0,0,0,.5-.5V5a.5.5,0,0,0-.5-.5Z"
                                    fill="white"
                                    fillRule="evenodd"
                                    opacity="0.3"
                                ></path>
                            </svg>
                            <span>usuń</span>
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

export default Edit;