import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import queryString from "query-string";
import {Search, Pagination} from "components";
import {Main, PageLoader} from "components";
import {Button} from "components";
import {getUsers, deleteUser} from "actions/users";
import ListItem from "./components/ListItem";

class UsersList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      query: queryString.parse(this.props.location.search).q,
    };
  }

  getUsers = () => {
    const {
      location: {search},
      getUsers,
    } = this.props;
    const {page, per_page, q} = queryString.parse(search);
    getUsers(page, per_page, q);
  };

  componentDidMount() {
    this.getUsers();
  }

  handleDelete = (id) => {
    this.props
      .deleteUser(id)
      .then(() => {
        this.props.getUsers();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const {history, location, users, getUsers} = this.props;
    const {query} = this.state;

    if (!users || users.status === "invalid" || users.status === "loading")
      return <PageLoader />;

    if (users.status === "failure") {
      return <p>Error</p>;
    }
    const {data, meta} = users;
    return (
      <Main breadcrumbs={[{name: "Użytkownicy"}]} title="Użytkownicy">
        <div className="dashboard">
          <header className="dashboard__header">
            <div className="icon__container">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
              >
                <rect fill="none" fillRule="evenodd" d="M0,0H20V20H0Z" />
                <path
                  fill="#494b74"
                  opacity="0.3"
                  d="M11.342,9.684a3.342,3.342,0,1,1,3.342-3.342A3.342,3.342,0,0,1,11.342,9.684Z"
                  transform="translate(-1.342 -0.493)"
                />
                <path
                  fill="#494b74"
                  d="M3,19.015C3.323,15.027,6.548,13,10.48,13c3.986,0,7.262,1.916,7.506,6.016a.573.573,0,0,1-.625.668H3.606A.924.924,0,0,1,3,19.015Z"
                  transform="translate(-0.493 -2.191)"
                />
              </svg>
            </div>
            <h2 className="dashboard__title heading">
              Zarządzanie użytkownikami
            </h2>
            <Button
              type="add"
              text="Dodaj użytkownika"
              onClick={() => history.push("/users/new")}
            />
          </header>
          <Search
            action={(page, per_page, query, loading) => {
              this.setState({query});
              getUsers(page, per_page, query, loading);
            }}
            defaultValue={query}
          />
          {data?.length > 0 ? (
            <>
              <table className="table">
                <thead className="table__header table-head">
                  <tr>
                    <th>Użytkownik</th>
                    <th>Adres e-mail</th>
                    <th>Data rejestracji</th>
                    <th width="200">Działania</th>
                  </tr>
                </thead>
                <tbody className="table__body table-body">
                  {data.map((props) => (
                    <ListItem
                      key={props.id}
                      handleDelete={this.handleDelete}
                      {...props}
                    />
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <div style={{padding: 20}}>
              <p>Brak użytkowników</p>
            </div>
          )}
          <Pagination
            data={{...meta, query}}
            history={history}
            location={location}
            action={(page, per_page) => getUsers(page, per_page, query)}
          />
        </div>
      </Main>
    );
  }
}

const mapStateToProps = ({users}) => ({users});

const mapDispatchToProps = (dispatch) => ({
  getUsers: (page, per_page, query, loading) =>
    dispatch(getUsers(page, per_page, query, loading)),
  deleteUser: (id) => dispatch(deleteUser(id)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UsersList));
