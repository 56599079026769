const initialState = {
  status: "invalid",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "QUESTIONS_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "QUESTIONS_SUCCESS":
      return {
        ...state,
        status: "success",
        ...action,
      };
    case "QUESTIONS_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    case "ADD_QUESTION":
      return {
        ...state,
        data: [...state.data, action.data],
      };
    case "DELETE_QUESTION":
      return {
        ...state,
        data: state.data.filter(({ id }) => id !== action.id),
      };
    case "UPDATE_QUESTION": {
      const questions = [...state.data];
      const index = questions.indexOf(questions.find(({ id }) => id === action.data.id));
      if (index > -1) {
        questions[index] = action.data;
        return {
          ...state,
          data: questions,
        };
      } else {
        return {
          ...state,
        };
      }
    }

    default:
      return state;
  }
};
