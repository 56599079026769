import React from "react";
import Button from "./Button";
import {withRouter} from "react-router-dom";

const ActionBar = ({
  deleteAction = null,
  deleteText,
  saveAction,
  disableSave,
  cancel,
  history,
}) => {
  return (
    <div className="actionbar">
      <div
        className={`actionbar__container align-${
          deleteAction ? "space" : "right"
        }`}
      >
        {deleteAction ? (
          <div className="actionbar__group">
            <Button type="delete" onClick={deleteAction} text={deleteText} />
          </div>
        ) : null}
        <div className="actionbar__group">
          {cancel && (
            <Button type="cancel" onClick={() => history.push(cancel)} />
          )}
          {saveAction ? (
            <Button type="save" disabled={disableSave} onClick={saveAction} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default withRouter(ActionBar);
