import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { PageLoader } from "components";

import { AddQuestion, EditQuestion, QuestionsList, FormTime } from "./components";
import { getQuestions } from "actions/questions";

const FormQuest = ({ movie, questions, getQuestions, match }) => {
  useEffect(() => {
    getQuestions(movie.data.form.id);
  }, []);
  if (!questions || questions.status === "invalid" || questions.status === "loading") return <PageLoader />;

  if (questions.status === "failure") {
    return <p>Error</p>;
  }
  return (
    <div className="dashboard-wrapper-flex">
      <div className="column-half">
        {questions.data.map(
          data =>
            data.id === match.params.question_id && (
              <EditQuestion
                key={data.id}
                form={`edit-question-${match.params.question_id}`}
                initialValues={{
                  content: data.content,
                  question_options_attributes: data.question_options
                    .sort((item, next) => item.id - next.id)
                    .map(item => ({
                      id: item.id,
                      content: item.content,
                    })),
                  correct: data.question_options
                    .sort((item, next) => item.id - next.id)
                    .indexOf(
                      data.question_options.sort((item, next) => item.id - next.id).find(({ correct }) => correct)
                    ),
                }}
              />
            )
        )}

        {!!!match.params.question_id && <AddQuestion />}
      </div>
      <div className="column-half">
        <QuestionsList />
        <FormTime />
      </div>
    </div>
  );
};

export default connect(
  ({ movie, questions }) => ({ movie, questions }),
  dispatch => ({
    getQuestions: form_id => dispatch(getQuestions(form_id)),
  })
)(withRouter(FormQuest));
