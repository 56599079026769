import React, {PureComponent} from "react";
import Button from "../Button";
import ReactDOM from "react-dom";

class ModalDelete extends PureComponent {
  state = {
    isActive: false,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({isActive: true});
    }, 250);
  }

  render() {
    const {handleClose, handleDelete, name} = this.props;
    return ReactDOM.createPortal(
      <div className="popup-wrapper">
        <div className={`popup ${this.state.isActive ? "active" : ""}`}>
          <header className="popup__header">
            <div className="icon__container">
              <svg
                id="Group_377"
                data-name="Group 377"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <rect
                  id="Rectangle_378"
                  data-name="Rectangle 378"
                  width="24"
                  height="24"
                  fill="none"
                />
                <path
                  id="Path_227"
                  data-name="Path 227"
                  d="M6,8H18l-.893,11.615A1.5,1.5,0,0,1,15.611,21H8.389a1.5,1.5,0,0,1-1.5-1.385Z"
                  fill="#597DFF"
                />
                <path
                  id="Path_228"
                  data-name="Path 228"
                  d="M14,4.5v-1a.5.5,0,0,0-.5-.5h-3a.5.5,0,0,0-.5.5v1H5.5A.5.5,0,0,0,5,5v.5a.5.5,0,0,0,.5.5h13a.5.5,0,0,0,.5-.5V5a.5.5,0,0,0-.5-.5Z"
                  fill="#597DFF"
                  fillRule="evenodd"
                  opacity="0.3"
                />
              </svg>
            </div>
            <h2 className="dashboard__title heading">Usuń</h2>
            <Button type="close" onClick={handleClose} />
          </header>
          <section className="popup__body">
            <p className="popup-text">
              Czy na pewno chcesz usunąć <strong>{name}</strong>
            </p>
          </section>
          <div
            className="popup__footer"
            style={{
              padding: "10px 15px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button type="cancel" text="Nie usuwaj" onClick={handleClose} />
            <Button type="delete" text=" Tak, usuń" onClick={handleDelete} />
          </div>
        </div>
      </div>,
      document.getElementById("root")
    );
  }
}

export default ModalDelete;
