import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import queryString from "query-string";
import {PageLoader, Search, Pagination, Button} from "components";
import {
  getActorsCategories,
  deleteActorCategory,
} from "actions/actors_categories";
import {ListItem} from "./components";

class CategoriesList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      query: queryString.parse(this.props.location.search).q,
    };
  }
  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const {
      location: {search},
      getActorsCategories,
    } = this.props;
    const {page, per_page, q} = queryString.parse(search);
    getActorsCategories(page, per_page, q);
  };

  handleDelete = (id) => {
    deleteActorCategory(id)
      .then(() => this.getData())
      .catch((err) => console.log(err));
  };
  render() {
    const {history, actors_categories, location} = this.props;
    const {query} = this.state;

    if (
      actors_categories.status === "invalid" ||
      actors_categories.status === "loading"
    )
      return <PageLoader />;

    if (actors_categories.status === "failure") {
      return <p>Error</p>;
    }
    const {data, meta} = actors_categories;

    return (
      <div className="dashboard">
        <header className="dashboard__header">
          <div className="icon__container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
            >
              <rect fill="none" width="20" height="20" />
              <rect fill="none" width="20" height="20" />
              <path
                fill="#494b74"
                fillRule="evenodd"
                opacity="0.3"
                d="M4.833,5H16.5a.833.833,0,0,1,0,1.667H4.833A.833.833,0,0,1,4.833,5Zm0,6.667H16.5a.833.833,0,0,1,0,1.667H4.833a.833.833,0,0,1,0-1.667Z"
                transform="translate(-0.667 -0.833)"
              />
              <path
                fill="#494b74"
                fillRule="evenodd"
                d="M4.833,9H16.5a.833.833,0,0,1,0,1.667H4.833A.833.833,0,0,1,4.833,9Zm0,6.667H16.5a.833.833,0,0,1,0,1.667H4.833a.833.833,0,0,1,0-1.667Z"
                transform="translate(-0.667 -1.5)"
              />
            </svg>
          </div>
          <h2 className="dashboard__title heading">Lista kategorii</h2>
          <Button
            type="add"
            text="Dodaj kategorie"
            onClick={() => history.push("/actors_categories/new")}
          />
        </header>
        <Search
          action={(page, per_page, query, loading) => {
            this.setState({query});
            getActorsCategories(page, per_page, query, loading);
          }}
          defaultValue={query}
        />
        {data?.length > 0 ? (
          <>
            <table className="table">
              <thead className="table__header table-head">
                <tr>
                  <th>Nazwa kategorii</th>
                  <th>Liczba postaci</th>
                  <th width="200">Działania</th>
                </tr>
              </thead>
              <tbody className="table__body table-body">
                {data.map((item) => (
                  <ListItem
                    key={item.id}
                    {...item}
                    handleDelete={this.handleDelete}
                  />
                ))}
              </tbody>
            </table>
          </>
        ) : (
          <div style={{padding: 20}}>
            <p>Brak kategorii</p>
          </div>
        )}
        <Pagination
          data={{...meta, query}}
          history={history}
          location={location}
          action={(page, per_page) =>
            getActorsCategories(page, per_page, query)
          }
        />
      </div>
    );
  }
}

const mapStateToProps = ({actors_categories}) => ({actors_categories});

const mapDispatchToProps = (dispatch) => ({
  getActorsCategories: (page, per_page, query, loading) =>
    dispatch(getActorsCategories(page, per_page, query, loading)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CategoriesList));
