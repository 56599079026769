import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {Main, AsyncSelect} from "components";
import {Field, reduxForm} from "redux-form";
import {
  Input,
  ActionBar,
  Textarea,
  ImageUploader,
  Editor,
} from "components";
import {addActor} from "actions/actors";
import {client} from "utils/api";
import {toBase64} from "utils/helpers";

const InputField = (props) => <Input {...props} />;
const TextareaField = (props) => <Textarea {...props} />;

class ActorNew extends PureComponent {
  state = {
    image_preview: null,
    image: null,
  };
  onSubmit = ({
    name,
    intro,
    profession,
    content,
    seo_content_attributes,
    category_joins_attributes,
  }) => {
    let body = new FormData();

    body.append(`actor[image]`, this.state.image);
    body.append(`actor[name]`, name);
    body.append(`actor[intro]`, intro);
    body.append(`actor[profession]`, profession);
    body.append(`actor[content]`, content);
    body.append(
      `actor[category_joins_attributes][][category_id]`,
      category_joins_attributes?.category_id
    );
    body.append(
      `actor[seo_content_attributes][meta_title]`,
      seo_content_attributes?.meta_title
    );
    body.append(
      `actor[seo_content_attributes][meta_description]`,
      seo_content_attributes?.meta_description
    );
    body.append(
      `actor[seo_content_attributes][slug_url]`,
      seo_content_attributes?.slug_url
    );

    this.props
      .addActor(body)
      .then((id) => id && this.props.history.push(`/heroes/${id}/basic_info`));
  };
  loadOptions = (q) => {
    return new Promise(async (resolve, reject) => {
      try {
        const categories = await client.get(
          `categories?type=ActorCategory&sort[order]=asc&sort[column]=id&page=1&per_page=10${
            q ? `&q[name_matches]=%25${q}%25` : ""
          }`
        );
        const data = categories.data.data.map(({id, attributes}) => ({
          label: attributes.name,
          value: id,
        }));
        resolve(data);
      } catch (error) {
        console.log(error);
        reject([]);
      }
    });
  };

  handleUploadImage = (file) =>
    new Promise(async (resolve, reject) => {
      const image_preview = await toBase64(file);

      this.setState({image: file, image_preview}, () => resolve());
    });
  render() {
    const {handleSubmit, submiting, pristine, change} = this.props;
    const {image_preview} = this.state;
    return (
      <Main
        breadcrumbs={[
          {name: "Bohaterowie", href: "/heroes"},
          {name: "Dodaj Bohatera"},
        ]}
        title="Dodaj Bohatera"
      >
        <form noValidate onSubmit={handleSubmit(this.onSubmit)}>
          <div className="dashboard">
            <header className="dashboard__header">
              <div className="icon__container">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                >
                  <rect fill="none" width="20" height="20" />
                  <circle
                    fill="#494b74"
                    opacity="0.3"
                    cx="8.333"
                    cy="8.333"
                    r="8.333"
                    transform="translate(1.667 1.667)"
                  />
                  <rect
                    fill="#3699ff"
                    width="1.667"
                    height="5.833"
                    rx="0.833"
                    transform="translate(9.167 8.333)"
                  />
                  <rect
                    fill="#494b74"
                    width="1.667"
                    height="1.667"
                    rx="0.833"
                    transform="translate(9.167 5.833)"
                  />
                </svg>
              </div>
              <h2 className="dashboard__title heading">
                Podstawowe informacje
              </h2>
            </header>
            <div className="dashboard__form">
              <div className="dashboard-wrapper-flex">
                <div className="column-half">
                  <Field
                    label="Nazwa bohatera *"
                    name="name"
                    component={InputField}
                  />
                  <Field
                    label="Intro *"
                    name="intro"
                    component={TextareaField}
                  />
                  <Field
                    label="Zawód/Rola/Kim był?"
                    name="profession"
                    component={InputField}
                  />
                </div>
                <div className="column-half">
                  <Field
                    name="category_joins_attributes.category_id"
                    component={InputField}
                    type="hidden"
                  />
                  <AsyncSelect
                    label="Wybierz kategorie"
                    name="category"
                    onChange={({value}) =>
                      change("category_joins_attributes.category_id", value)
                    }
                    getData={this.loadOptions}
                  />
                  <ImageUploader
                    addText="dodaj zdjęcie"
                    url=""
                    images={image_preview ? [{path: image_preview}] : []}
                    handleUpload={this.handleUploadImage}
                    handleDelete={() =>
                      this.setState({
                        image_preview: null,
                        image: null,
                      })
                    }
                    limit={1}
                  />
                </div>
              </div>
            </div>
          </div>
          <Editor label="Opis" name="content" value="" change={change} />
          <div className="dashboard">
            <header className="dashboard__header">
              <div className="icon__container">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                >
                  <rect fill="none" width="20" height="20" />
                  <circle
                    fill="#494b74"
                    opacity="0.3"
                    cx="8.333"
                    cy="8.333"
                    r="8.333"
                    transform="translate(1.667 1.667)"
                  />
                  <rect
                    fill="#3699ff"
                    width="1.667"
                    height="5.833"
                    rx="0.833"
                    transform="translate(9.167 8.333)"
                  />
                  <rect
                    fill="#494b74"
                    width="1.667"
                    height="1.667"
                    rx="0.833"
                    transform="translate(9.167 5.833)"
                  />
                </svg>
              </div>
              <h2 className="dashboard__title heading">SEO</h2>
            </header>
            <div className="dashboard__form">
              <div className="form-row grid-2">
                <Field
                  label="Meta title"
                  name="seo_content_attributes.meta_title"
                  component={InputField}
                />
                <Field
                  label="Slug URL"
                  name="seo_content_attributes.slug_url"
                  component={InputField}
                />
              </div>
              <div className="form-row">
                <Field
                  label="Meta description"
                  name="seo_content_attributes.meta_description"
                  component={TextareaField}
                />
              </div>
            </div>
          </div>
        </form>
        <ActionBar
          saveAction={handleSubmit(this.onSubmit)}
          disableSave={pristine || submiting}
          cancel="/heroes"
        />
      </Main>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addActor: (id) => dispatch(addActor(id)),
});

export default connect(
  null,
  mapDispatchToProps
)(reduxForm({form: "actor_new", enableReinitialize: true})(ActorNew));
