import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Main, PageLoader, Select, Button, Input, AsyncSelect } from "components";
import { Field, reduxForm } from "redux-form";
import { editSection, getSection, addSectionVideo } from "actions/sections";
import { client } from "utils/api";

import Video from "./components/Video";

const series_options = [
  { label: 'Pierwsza', value: 'first' }, 
  { label: 'Druga', value: 'second' },
  { label: 'Trzecia', value: 'third' },
  { label: 'Czwarta', value: 'fourth' },
]

const InputField = props => <Input {...props} />;

class HomeEdit extends PureComponent {
  componentDidMount() {
    this.getSection();
  }

  getSection() {
    const {
      getSection,
      match: {
        params: { id },
      },
    } = this.props;
    getSection(id);
  }

  onSubmit = values => {
    const {
      match: {
        params: { id },
      },
      editSection,
    } = this.props;
    editSection(id, values);
  };

  loadOptions = q => {
    return new Promise(async (resolve, reject) => {
      try {
        const movies = await client.get(
          `videos?sort[order]=asc&sort[column]=id&page=1&per_page=10${q ? `&q[name_or_category_joins_category_name_matches]=%25${q}%25` : ""
          }`
        );
        const data = movies.data.data.map(({ id, attributes }) => ({
          label: attributes.name,
          value: id,
        }));
        resolve(data);
      } catch (error) {
        console.log(error);
        reject([]);
      }
    });
  };

  render() {
    const { section, history } = this.props;

    if (!section || section.status === "invalid" || section.status === "loading") return <PageLoader />;

    if (section.status === "failure") {
      return <p>Error</p>;
    }
    const { data } = section;
    const { handleSubmit, submiting, pristine, addSectionVideo, change } = this.props;
    return (
      <Main breadcrumbs={[{ name: data?.name }]} title={data?.name}>
        <div className="dashboard-wrapper">
          <div className="column">
            <div className="dashboard">
              <header className="dashboard__header">
                <div className="icon__container">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                    <rect fill="none" width="20" height="20" />
                    <circle
                      fill="#494b74"
                      opacity="0.3"
                      cx="8.333"
                      cy="8.333"
                      r="8.333"
                      transform="translate(1.667 1.667)"
                    />
                    <rect fill="#3699ff" width="1.667" height="5.833" rx="0.833" transform="translate(9.167 8.333)" />
                    <rect fill="#494b74" width="1.667" height="1.667" rx="0.833" transform="translate(9.167 5.833)" />
                  </svg>
                </div>
                <h2 className="dashboard__title heading">Podstawowe informacje</h2>
                <Button type="cancel" text="Anuluj" onClick={() => history.push("/home_page")} />
                <Button
                  type="save"
                  disabled={submiting || pristine}
                  onClick={handleSubmit(this.onSubmit)}
                  style={{ marginLeft: "15px" }}
                  text="Zapisz"
                />
              </header>
              <div className="dashboard__form">
                <form noValidate>
                  <div className="dashboard-wrapper-flex">
                    <div className="column-half">
                      <Field label="Nazwa sekcji*" name="name" component={InputField} />
                      <Field type="hidden" name="series" component={InputField} />
                      <AsyncSelect
                        label="Dodaj filmy"
                        name="videos"
                        cacheOptions={false}
                        initValue={data.section_videos.map(item => item.id)}
                        onChange={({ value: video_id }) => addSectionVideo(data.id, { video_id })}
                        getData={this.loadOptions}
                      />
                      <Select
                        label="Seria"
                        name="series"
                        defaultValue={series_options.find(({ value }) => value === data?.series)}
                        options={series_options}
                        handleChange={(value) => change('series', value)}
                      />
                    </div>
                    <div className="column-half">
                      {data?.section_videos?.length > 0 && (
                        <div className="video-boxes">
                          {data.section_videos.map(item => (
                            <Video section_id={data.id} section_video_id={item.id} key={item.id} {...item.video} />
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Main>
    );
  }
}

const mapStateToProps = ({ section }) => ({
  section,
  initialValues: { name: section?.data?.name, series: section?.data?.series },
});

const mapDispatchToProps = dispatch => ({
  editSection: (data, id) => dispatch(editSection(data, id)),
  getSection: id => dispatch(getSection(id)),
  addSectionVideo: (data, id) => dispatch(addSectionVideo(data, id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "section_edit", enableReinitialize: true })(withRouter(HomeEdit)));
