import {toastr} from "react-redux-toastr";
import {api_client} from "utils/api";
import {removeCookie, setCookie} from "utils/cookies";

export const handleLogin = (data) => (dispatch) => {
  api_client
    .post(`users/sign_in`, data.user)
    .then(({data: {auth_token}}) => {
      setCookie("token", auth_token).then(() => {
        window.location.pathname = "/";
      });
    })
    .catch(() => {
      toastr.error("Błąd", "Wystąpił błąd podzczas logowania");
    });
};
export const handleLogout = () => {
  removeCookie("token").then(() => (window.location.pathname = "/"));
};
