import React, {PureComponent} from "react";
import {withRouter} from "react-router-dom";
import {Main} from "components";
import {CategoriesList} from "./components";

class ActorsCategoriesList extends PureComponent {
  render() {
    return (
      <Main
        breadcrumbs={[{name: "Kategorie bohaterów"}]}
        title="Kategorie bohaterów"
      >
        <CategoriesList />
      </Main>
    );
  }
}

export default withRouter(ActorsCategoriesList);
