import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {Main} from "components";
import {Field, reduxForm} from "redux-form";
import {Input, ActionBar, Textarea, Editor} from "components";
import {addStaticPage} from "actions/static_pages";

const TextareaField = (props) => <Textarea {...props} />;
const InputField = (props) => <Input {...props} />;

class StaticNew extends PureComponent {
  onSubmit = (values) => {
    this.props
      .addStaticPage(values)
      .then((id) => id && this.props.history.push(`/static_pages/${id}`));
  };

  render() {
    const {handleSubmit, submiting, pristine, change} = this.props;
    return (
      <Main
        breadcrumbs={[{name: "Dodaj stronę statyczną"}]}
        title="Dodaj stronę statyczną"
      >
        <div className="dashboard">
          <header className="dashboard__header">
            <div className="icon__container">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
              >
                <rect fill="none" width="20" height="20" />
                <circle
                  fill="#494b74"
                  opacity="0.3"
                  cx="8.333"
                  cy="8.333"
                  r="8.333"
                  transform="translate(1.667 1.667)"
                />
                <rect
                  fill="#3699ff"
                  width="1.667"
                  height="5.833"
                  rx="0.833"
                  transform="translate(9.167 8.333)"
                />
                <rect
                  fill="#494b74"
                  width="1.667"
                  height="1.667"
                  rx="0.833"
                  transform="translate(9.167 5.833)"
                />
              </svg>
            </div>
            <h2 className="dashboard__title heading">Podstawowe informacje</h2>
          </header>
          <div className="dashboard__form">
            <div className="form-row">
              <Field
                label="Nazwa strony statycznej"
                name="name"
                component={InputField}
              />
            </div>
          </div>
        </div>
        <Editor name="content" value="" label="Opis" change={change} />
        <div className="dashboard">
          <header className="dashboard__header">
            <div className="icon__container">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
              >
                <rect fill="none" width="20" height="20" />
                <circle
                  fill="#494b74"
                  opacity="0.3"
                  cx="8.333"
                  cy="8.333"
                  r="8.333"
                  transform="translate(1.667 1.667)"
                />
                <rect
                  fill="#3699ff"
                  width="1.667"
                  height="5.833"
                  rx="0.833"
                  transform="translate(9.167 8.333)"
                />
                <rect
                  fill="#494b74"
                  width="1.667"
                  height="1.667"
                  rx="0.833"
                  transform="translate(9.167 5.833)"
                />
              </svg>
            </div>
            <h2 className="dashboard__title heading">SEO</h2>
          </header>
          <div className="dashboard__form">
            <div className="form-row grid-2">
              <Field
                label="Meta title"
                name="seo_content_attributes.meta_title"
                component={InputField}
              />
              <Field
                label="Slug URL"
                name="seo_content_attributes.slug_url"
                component={InputField}
              />
            </div>
            <div className="form-row">
              <Field
                label="Meta description"
                name="seo_content_attributes.meta_description"
                component={TextareaField}
              />
            </div>
          </div>
        </div>
        <ActionBar
          saveAction={handleSubmit(this.onSubmit)}
          disableSave={pristine || submiting}
          cancel="/static_pages"
        />
      </Main>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addStaticPage: (data) => dispatch(addStaticPage(data)),
});

export default connect(
  null,
  mapDispatchToProps
)(reduxForm({form: "static_page_new", enableReinitialize: true})(StaticNew));
