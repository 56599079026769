import {toastr} from "react-redux-toastr";
import {client, apiFormatter} from "utils/api";

export const getActorsCategories = (
  page = 1,
  per_page = 10,
  query = null,
  loading = true
) => async (dispatch) => {
  loading && dispatch({type: "ACTORS_CATEGORIES_LOADING"});
  await client
    .get(
      `categories?type=ActorCategory&sort[order]=asc&sort[column]=id&page=${page}&per_page=${per_page}${
        query ? `&q[name_matches]=%25${query}%25` : ""
      }`
    )
    .then(({data}) => {
      dispatch({
        type: "ACTORS_CATEGORIES_SUCCESS",
        data: apiFormatter(data),
        meta: data.meta,
      });
    })
    .catch(() => {
      dispatch({type: "ACTORS_CATEGORIES_FAILURE"});
      toastr.error("Błąd", "Wystąpił błąd podzczas pobierania");
    });
};
export const createActorCategory = (data) =>
  new Promise((resolve, reject) => {
    client
      .post("categories", {...data, type: "ActorCategory"})
      .then(({data}) => {
        resolve(data);
        toastr.success("Sukces", "Dodano kategorię");
      })
      .catch(() => {
        toastr.error("Błąd", "Wystąpił błąd podzczas usuwania");
        reject();
      });
  });

export const deleteActorCategory = (id) =>
  new Promise((resolve, reject) => {
    client
      .delete(`categories/${id}`)
      .then(() => {
        toastr.success("Sukces", "Usunięto kategorię");
        resolve();
      })
      .catch(() => {
        toastr.error("Błąd", "Wystąpił błąd podzczas dodawania");
        reject();
      });
  });
