import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Input, Button } from "components";
import { updateForm } from "actions/questions";

const InputField = props => <Input {...props} />;
const FormTime = ({ movie, pristine, handleSubmit, updateForm }) => {
  const onSubmit = values => {
    updateForm(movie.data.form.id, values);
  };
  return (
    <div className="dashboard">
      <form style={{ padding: 20 }} noValidate onSubmit={handleSubmit(onSubmit)}>
        <Field label="Szacowany czas na uzupełnienie ankiety" name="estimated_time" component={InputField} />
        <Button type="save" disabled={pristine} onClick={handleSubmit(onSubmit)} />
      </form>
    </div>
  );
};

export default connect(
  ({ movie }) => ({ movie, initialValues: { estimated_time: movie?.data?.form?.estimated_time } }),
  dispatch => ({
    updateForm: (form_id, data) => dispatch(updateForm(form_id, data)),
  })
)(reduxForm({ form: "update-from", enableReinitialize: true })(withRouter(FormTime)));
