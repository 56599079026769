import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {Main} from "components";
import {Field, reduxForm} from "redux-form";
import {Button, Input} from "components";
import {addUser} from "actions/users";

const InputField = (props) => <Input {...props} />;

class UsersNew extends PureComponent {
  onSubmit = (values) => {
    this.props
      .addUser(values)
      .then((id) => id && this.props.history.push(`/users/${id}`));
  };

  render() {
    const {handleSubmit} = this.props;
    return (
      <Main
        breadcrumbs={[{name: "Dodaj użytkownika"}]}
        title="Dodaj użytkownika"
      >
        <div className="dashboard-wrapper-flex">
          <div className="column-half">
            <div className="dashboard">
              <header className="dashboard__header">
                <div className="icon__container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                  >
                    <rect fill="none" width="20" height="20" />
                    <circle
                      fill="#494b74"
                      opacity="0.3"
                      cx="8.333"
                      cy="8.333"
                      r="8.333"
                      transform="translate(1.667 1.667)"
                    />
                    <rect
                      fill="#3699ff"
                      width="1.667"
                      height="5.833"
                      rx="0.833"
                      transform="translate(9.167 8.333)"
                    />
                    <rect
                      fill="#494b74"
                      width="1.667"
                      height="1.667"
                      rx="0.833"
                      transform="translate(9.167 5.833)"
                    />
                  </svg>
                </div>
                <h2 className="dashboard__title heading">
                  Podstawowe informacje
                </h2>
                <Button type="cancel" text="Anuluj" />
                <Button
                  type="save"
                  style={{marginLeft: "15px"}}
                  onClick={handleSubmit(this.onSubmit)}
                  text="Zapisz"
                />
              </header>
              <div className="dashboard__form">
                <form noValidate>
                  <div className="form-row">
                    <Field
                      label="Imie i nazwisko*"
                      name="name"
                      component={InputField}
                    />
                  </div>
                  <div className="form-row">
                    <Field
                      label="Adres e-mail*"
                      name="email"
                      component={InputField}
                    />
                  </div>
                  <div className="form-row">
                    <Field
                      label="Utwórz nowe hasło*"
                      name="password"
                      component={InputField}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Main>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addUser: (id) => dispatch(addUser(id)),
});

export default connect(
  null,
  mapDispatchToProps
)(reduxForm({form: "users_new", enableReinitialize: true})(UsersNew));
