import React from "react";

export default function Movies() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            {/* <defs><style>.a{fill:none;}.a,.c{fill - rule:evenodd;}.b,.c{fill:#494b74;}.b{opacity:0.3;}</style></defs> */}
            <path fill='none' fillRule='evenodd' d="M0,0H20V20H0Z" />
            <path fill='#494b74' opacity='0.3' d="M5.548,2h6.566a1.25,1.25,0,0,1,.807.3l3.97,3.358a1.25,1.25,0,0,1,.443.954V17.069c0,1.492-.017,1.6-1.548,1.6H5.548c-1.531,0-1.548-.105-1.548-1.6V3.6C4,2.105,4.017,2,5.548,2Z" transform="translate(-0.667 -0.333)" />
            <path fill='#494b74' fillRule='evenodd' d="M10.652,14.647l3.67-2.508a.417.417,0,0,0,0-.687l-3.67-2.524A.417.417,0,0,0,10,9.27V14.3a.417.417,0,0,0,.652.344Z" transform="translate(-1.667 -1.476)" />
        </svg>
    );

}