import React from 'react'

const Input = ({
  meta,
  input,
  label = null,
  type,
  postfix,
  prefix,
  color,
  disabled,
  value,
  defaultValue,
  labelAside
}) => {
  return (
    <div
      style={{ display: type === 'hidden' ? 'none' : null }}
      className={`form-group ${meta.touched && meta.valid ? 'visited' : ''} ${
        meta.touched && meta.invalid ? 'error' : ''
      } ${labelAside ? 'label-aside' : ''}`}>
      <div className="inp-wrapper">
        {label && <label htmlFor={input.name} className="label-form">
          {label}
        </label>}

        <div className={`inp-group `}>
          {prefix && <span className="form-span prefix">{prefix}</span>}
          <input
            className={disabled ? 'input-disabled' : ''}
            disabled={disabled}
            id={input.name}
            {...input}
            type={type}
          />
          {postfix && (
            <span className="form-span postfix">
              {postfix === 'color' ? (
                <div
                  className="postfix-color"
                  style={{ backgroundColor: color }}></div>
              ) : (
                postfix
              )}
            </span>
          )}
        </div>
      </div>
      {meta.touched && meta.invalid && meta.error && (
        <p className="inp-error">{meta.error}</p>
      )}
    </div>
  )
}

export default Input
