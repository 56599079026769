import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { Input, ActionBar, Textarea, Checkbox, ImageUploader, AsyncSelect, AsyncMultiSelect, Editor, EditorSimple } from "components";
import { editMovie } from "actions/movies";
import { client, url } from "utils/api";
import { jsonToFormData } from "utils/helpers";

const InputField = props => <Input {...props} />;
const TextareaField = props => <Textarea {...props} />;
const CheckField = props => <Checkbox {...props} />;

const BasicData = ({
  handleSubmit,
  submiting,
  pristine,
  change,
  movie,
  initialValues,
  editMovie,
  movie: {
    data: { id },
  },
}) => {
  const [is_video_hero_selected, setVideoHero] = useState(
    !!movie?.data?.video_hero?.preview_url || !!movie?.data?.video_hero?.tag_name
  );
  const [categories, setCategories] = useState(
    movie?.data?.category_joins.map(({ category, id }) => ({
      label: category.name,
      value: category.id,
      id,
    }))
  );
  const onSubmit = async values => {
    editMovie(id, jsonToFormData({ video: values }));
  };
  const loadCategoryOptions = q => {
    return new Promise(async (resolve, reject) => {
      try {
        const categories = await client.get(
          `categories?type=VideoCategory&sort[order]=asc&sort[column]=id&page=1&per_page=10${q ? `&q[name_matches]=%25${q}%25` : ""
          }`
        );
        const data = categories.data.data.map(({ id, attributes }) => ({
          label: attributes.name,
          value: id,
        }));
        resolve(data);
      } catch (error) {
        console.log(error);
        reject([]);
      }
    });
  };
  const loadHeroOptions = q => {
    return new Promise(async (resolve, reject) => {
      try {
        const categories = await client.get(
          `actors?sort[order]=asc&sort[column]=id&page=1&per_page=10${q ? `&q[name_matches]=%25${q}%25` : ""}`
        );
        const data = categories.data.data.map(({ id, attributes }) => ({
          label: attributes.name,
          value: id,
        }));
        resolve(data);
      } catch (error) {
        console.log(error);
        reject([]);
      }
    });
  };
  const handleUploadImageHero = file =>
    new Promise(async (resolve, reject) => {
      editMovie(
        id,
        jsonToFormData({ video: { video_hero_attributes: { preview: file, id: movie?.data?.video_hero?.id } } })
      )
        .then(() => resolve())
        .catch(() => reject());
    });
  const handleDeleteImageHero = file =>
    new Promise(async (resolve, reject) => {
      editMovie(
        id,
        jsonToFormData({ video: { video_hero_attributes: { preview: null, id: movie?.data?.video_hero?.id } } })
      )
        .then(() => resolve())
        .catch(() => reject());
    });
  const handleUploadPreviewImage = file =>
    new Promise(async (resolve, reject) => {
      editMovie(movie?.data?.id, jsonToFormData({ video: { preview: file } }))
        .then(() => resolve())
        .catch(() => reject());
    });
  const handleDeletePreviewImage = file =>
    new Promise(async (resolve, reject) => {
      editMovie(movie?.data?.id, jsonToFormData({ video: { preview: null } }))
        .then(() => resolve())
        .catch(() => reject());
    });

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <div className="dashboard">
        <header className="dashboard__header">
          <div className="icon__container">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
              <rect fill="none" width="20" height="20" />
              <circle fill="#494b74" opacity="0.3" cx="8.333" cy="8.333" r="8.333" transform="translate(1.667 1.667)" />
              <rect fill="#3699ff" width="1.667" height="5.833" rx="0.833" transform="translate(9.167 8.333)" />
              <rect fill="#494b74" width="1.667" height="1.667" rx="0.833" transform="translate(9.167 5.833)" />
            </svg>
          </div>
          <h2 className="dashboard__title heading">Podstawowe informacje</h2>
        </header>
        <div className="dashboard__form">
          <div className="dashboard-wrapper-flex">
            <div className="column-half">
              <Field label="Nazwa filmu *" name="name" component={InputField} />
              <Field label="Link do filmu *" name="url" component={InputField} />
              <AsyncMultiSelect
                label="Wybierz kategorie"
                getData={loadCategoryOptions}
                onChange={(value, { action, option, removedValue }) => {
                  const updateCategories = data =>
                    setCategories(
                      data.category_joins.map(({ category, id }) => ({
                        label: category.name,
                        value: category.id,
                        id,
                      }))
                    );
                  switch (action) {
                    case "select-option":
                      return editMovie(
                        id,
                        jsonToFormData({
                          video: { category_joins_attributes: [{ category_id: option.value }] },
                        })
                      ).then(data => updateCategories(data));
                    case "clear":
                      return editMovie(
                        id,
                        jsonToFormData({
                          video: { category_joins_attributes: categories.map(({ id }) => ({ id, _destroy: 1 })) },
                        })
                      ).then(data => updateCategories(data));

                    case "remove-value":
                      return editMovie(
                        id,
                        jsonToFormData({
                          video: {
                            category_joins_attributes: [{ id: removedValue.id, _destroy: 1 }],
                          },
                        })
                      ).then(data => updateCategories(data));
                    default:
                      break;
                  }
                }}
                initValue={categories}
              />
              <Field name="actor_video_attributes.actor_id" component={InputField} type="hidden" />
              <AsyncSelect
                label="Postać"
                initValue={
                  movie?.data?.actor_video
                    ? {
                      label: movie?.data?.actor_video?.actor?.name,
                      value: movie?.data?.actor_video?.actor?.id,
                    }
                    : {}
                }
                onChange={({ value }) => change("actor_video_attributes.actor_id", value)}
                getData={loadHeroOptions}
              />
              <div className="form-group">
                <div className="check-group">
                  <input
                    id="check_hero"
                    type="checkbox"
                    checked={is_video_hero_selected}
                    onChange={() => setVideoHero(!is_video_hero_selected)}
                  />
                  <label htmlFor="check_hero" className="label-form">
                    Oznacz ten film jako Video Hero
                  </label>
                </div>
              </div>
              {is_video_hero_selected && (
                <div className="form-row grid-2">
                  <Field name="video_hero_attributes.id" component={InputField} type="hidden" />

                  <ImageUploader
                    addText="dodaj zdjęcie"
                    label="Miniaturka"
                    url=""
                    images={
                      movie?.data?.video_hero?.preview_url
                        ? [{ path: `${url}${movie.data.video_hero.preview_url}` }]
                        : []
                    }
                    handleUpload={handleUploadImageHero}
                    handleDelete={handleDeleteImageHero}
                    limit={1}
                  />
                  <Field label="Tag" name="video_hero_attributes.tag_name" component={InputField} />
                </div>
              )}
            </div>
            <div className="column-half">
              <Field name="published" component={CheckField} label="Publikuj" />
              <Field label="Intro" name="intro" minHeight={120} component={TextareaField} />
              <div className="form-row grid-2">
                <ImageUploader
                  addText="dodaj zdjęcie"
                  url={url}
                  images={movie?.data?.preview_url ? [{ path: movie.data.preview_url }] : []}
                  handleUpload={handleUploadPreviewImage}
                  handleDelete={handleDeletePreviewImage}
                  limit={1}
                />
                <Field label="Czas trwania*" name="duration" component={InputField} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Editor label="Opis" name="description" value={initialValues.description} change={change} />
      <div className="dashboard">
        <header className="dashboard__header">
          <div className="icon__container">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
              <rect fill="none" width="20" height="20" />
              <circle fill="#494b74" opacity="0.3" cx="8.333" cy="8.333" r="8.333" transform="translate(1.667 1.667)" />
              <rect fill="#3699ff" width="1.667" height="5.833" rx="0.833" transform="translate(9.167 8.333)" />
              <rect fill="#494b74" width="1.667" height="1.667" rx="0.833" transform="translate(9.167 5.833)" />
            </svg>
          </div>
          <h2 className="dashboard__title heading">SEO</h2>
        </header>
        <div className="dashboard__form">
          <div className="form-row grid-2">
            <Field label="Meta title" name="seo_content_attributes.meta_title" component={InputField} />
            <Field label="Slug URL" name="seo_content_attributes.slug_url" component={InputField} />
          </div>
          <div className="form-row">
            <Field label="Meta description" name="seo_content_attributes.meta_description" component={TextareaField} />
          </div>
        </div>
      </div>
      <div className="dashboard">
        <header className="dashboard__header">
          <div className="icon__container">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
              <rect fill="none" width="20" height="20" />
              <circle fill="#494b74" opacity="0.3" cx="8.333" cy="8.333" r="8.333" transform="translate(1.667 1.667)" />
              <rect fill="#3699ff" width="1.667" height="5.833" rx="0.833" transform="translate(9.167 8.333)" />
              <rect fill="#494b74" width="1.667" height="1.667" rx="0.833" transform="translate(9.167 5.833)" />
            </svg>
          </div>
          <h2 className="dashboard__title heading">Źródło</h2>
        </header>
        <div className="dashboard__form">
          <div className="form-row grid-2">
            <Field label="Nazwa" name="source_attributes.name" component={InputField} />
          </div>
          <EditorSimple label="Treść" name="source_attributes.content" value={initialValues.source_attributes.content} change={change} />
        </div>
      </div>
      <ActionBar saveAction={handleSubmit(onSubmit)} disableSave={pristine || submiting} cancel="/" />
    </form>
  );
};

const mapStateToProps = ({ movie }) => ({
  movie,
  initialValues: {
    published: movie?.data?.published,
    name: movie?.data?.name,
    description: movie?.data?.description,
    intro: movie?.data?.intro,
    url: movie?.data?.url,
    duration: movie?.data?.duration,
    seo_content_attributes: {
      meta_title: movie?.data?.seo_content?.meta_title,
      meta_description: movie?.data?.seo_content?.meta_description,
      slug_url: movie?.data?.seo_content?.slug_url,
    },
    source_attributes: {
      id: movie?.data?.source?.id,
      name: movie?.data?.source?.name,
      content: movie?.data?.source?.content,
    },
    video_hero_attributes: {
      tag_name: movie?.data?.video_hero?.tag_name,
      id: movie?.data?.video_hero?.id,
    },
    actor_video_attributes: {
      actor_id: movie?.data?.actor_video ? movie?.data?.actor_video.actor?.id : null,
    },
  },
});

const mapDispatchToProps = dispatch => ({
  editMovie: (data, id) => dispatch(editMovie(data, id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "movies_edit_basic_data", enableReinitialize: true })(withRouter(BasicData)));
