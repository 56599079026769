import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { Main,Button, Search, Pagination, PageLoader } from "components";
import { getMovies, deleteMovie } from "actions/movies";
import ListItem from "./components/ListItem";

class MoviesList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      query: queryString.parse(this.props.location.search).q,
    };
  }

  getMovies = () => {
    const {
      location: { search },
      getMovies,
    } = this.props;
    const { page, per_page, q } = queryString.parse(search);
    getMovies(page, per_page, q);
  };

  componentDidMount() {
    this.getMovies();
  }

  handleDelete = id => {
    this.props
      .deleteMovie(id)
      .then(() => {
        this.getMovies();
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    const { history, location, movies, getMovies } = this.props;
    const { query } = this.state;

    if (!movies || movies.status === "invalid" || movies.status === "loading") return <PageLoader />;

    if (movies.status === "failure") {
      return <p>Error</p>;
    }
    const { data, meta } = movies;

    return (
      <Main breadcrumbs={[{ name: "filmy" }]} title="Filmy">
        <div className="dashboard">
          <header className="dashboard__header">
            <div className="icon__container">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <g opacity="0.9">
                  <rect fill="none" width="20" height="20" />
                  <rect fill="#3699ff" width="5.833" height="5.833" rx="1.5" transform="translate(3.333 3.333)" />
                  <path
                    fill="#3699ff"
                    fillRule="evenodd"
                    opacity="0.3"
                    d="M5.25,11.5H8.583a1.25,1.25,0,0,1,1.25,1.25v3.333a1.25,1.25,0,0,1-1.25,1.25H5.25A1.25,1.25,0,0,1,4,16.083V12.75A1.25,1.25,0,0,1,5.25,11.5ZM12.75,4h3.333a1.25,1.25,0,0,1,1.25,1.25V8.583a1.25,1.25,0,0,1-1.25,1.25H12.75a1.25,1.25,0,0,1-1.25-1.25V5.25A1.25,1.25,0,0,1,12.75,4Zm0,7.5h3.333a1.25,1.25,0,0,1,1.25,1.25v3.333a1.25,1.25,0,0,1-1.25,1.25H12.75a1.25,1.25,0,0,1-1.25-1.25V12.75A1.25,1.25,0,0,1,12.75,11.5Z"
                    transform="translate(-0.667 -0.667)"
                  />
                </g>
              </svg>
            </div>
            <h2 className="dashboard__title heading">Zarządzaj filmami</h2>
            <Button type="add" text="Dodaj film" onClick={() => history.push("/videos/new")} />
          </header>
          <Search
            action={(page, per_page, query, loading) => {
              this.setState({ query });
              getMovies(page, per_page, query, loading);
            }}
            defaultValue={query}
          />
          {data?.length > 0 ? (
            <>
              <table className="table">
                <thead className="table__header table-head">
                  <tr>
                    <th>Film</th>
                    <th>Kategoria</th>
                    <th>Wyświetlenia</th>
                    <th>Ocena</th>
                    <th width="200">Działania</th>
                  </tr>
                </thead>
                <tbody className="table__body table-body">
                  {data.map(props => (
                    <ListItem key={props.id} handleDelete={id => this.handleDelete(id)} {...props} />
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <div style={{ padding: 20 }}>
              <p>Brak Filmów</p>
            </div>
          )}
          <Pagination
            data={{ ...meta, query }}
            history={history}
            location={location}
            action={(page, per_page) => getMovies(page, per_page, query)}
          />
        </div>
      </Main>
    );
  }
}

const mapStateToProps = ({ movies }) => ({ movies });

const mapDispatchToProps = dispatch => ({
  deleteMovie: id => dispatch(deleteMovie(id)),
  getMovies: (page, per_page, query, loading) => dispatch(getMovies(page, per_page, query, loading)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MoviesList));
