import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Rating from "react-rating";
import { Pagination, PageLoader } from "components";
import { getComments, deleteComment } from "actions/comments";
import ListItem from "./components/ListItem";
import queryString from "query-string";
const Comments = ({ getComments, comments, movie, history, location }) => {
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const { page, per_page } = queryString.parse(location.search);
    getComments(movie.data.id, page, per_page);
  };

  const handleDelete = id => {
    deleteComment(id)
      .then(() => {
        getData();
      })
      .catch(err => {
        console.log(err);
      });
  };

  if (!comments || comments.status === "invalid" || comments.status === "loading") return <PageLoader />;

  if (comments.status === "failure") {
    return <p>Error</p>;
  }
  return (
    <div className="dashboard">
      <header className="dashboard__header">
        <h2 className="dashboard__rating">
          <div className="dashboard__rating__rate">
            <span className="value">{movie?.data?.video_rating ? movie.data.video_rating.toFixed(2) : 0}</span>
            <div className="rating-wrapper">
              <Rating
                initialRating={movie?.data?.video_rating}
                readonly={true}
                fullSymbol={
                  <svg xmlns="http://www.w3.org/2000/svg" width="19.511" height="18.67" viewBox="0 0 19.511 18.67">
                    <path
                      fill="#3699ff"
                      d="M13.394,20.29,8.628,22.8a1.167,1.167,0,0,1-1.693-1.23l.91-5.306L3.991,12.5a1.167,1.167,0,0,1,.647-1.99l5.328-.774L12.347,4.91a1.167,1.167,0,0,1,2.092,0l2.383,4.828,5.328.774A1.167,1.167,0,0,1,22.8,12.5l-3.855,3.758.91,5.306a1.167,1.167,0,0,1-1.693,1.23Z"
                      transform="translate(-3.638 -4.259)"
                    />
                  </svg>
                }
                emptySymbol={
                  <svg xmlns="http://www.w3.org/2000/svg" width="19.511" height="18.67" viewBox="0 0 19.511 18.67">
                    <path
                      fill="#3699ff"
                      opacity="0.3"
                      d="M13.394,20.29,8.628,22.8a1.167,1.167,0,0,1-1.693-1.23l.91-5.306L3.991,12.5a1.167,1.167,0,0,1,.647-1.99l5.328-.774L12.347,4.91a1.167,1.167,0,0,1,2.092,0l2.383,4.828,5.328.774A1.167,1.167,0,0,1,22.8,12.5l-3.855,3.758.91,5.306a1.167,1.167,0,0,1-1.693,1.23Z"
                      transform="translate(-3.638 -4.259)"
                    />
                  </svg>
                }
              />
            </div>
          </div>
          <div className="dashboard__rating__comments">
            <span>{comments.meta.total_count} komentarzy</span>
          </div>
        </h2>
      </header>
      {comments?.data?.length > 0 ? (
        <>
          <table className="table">
            <thead className="table__header table-head">
              <tr>
                <th width="50%">Komentarz</th>
                <th width="20%">Data</th>
                <th width="15%">Ocena</th>
                <th width="15%">Działania</th>
              </tr>
            </thead>
            <tbody className="table__body table-body">
              {comments.data.map(props => (
                <ListItem key={props.id} handleDelete={handleDelete} {...props} />
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <div style={{ padding: 20 }}>
          <p>Brak komentarzy</p>
        </div>
      )}
      <Pagination
        data={comments.meta}
        history={history}
        location={location}
        action={(page, per_page) => getComments(movie.data.id, page, per_page)}
      />
    </div>
  );
};

export default connect(
  ({ movie, comments }) => ({ movie, comments }),
  dispatch => ({
    getComments: (movie_id, page, per_page) => dispatch(getComments(movie_id, page, per_page)),
  })
)(withRouter(Comments));
