import React, {useState} from "react";
import {withRouter} from "react-router-dom";
import {Button, ModalDelete} from "components";

const ListItem = ({
  id,
  name,
  actor_videos,
  category_joins,
  history,
  handleDeleteActor,
}) => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  return (
    <>
      {isDeleteModalOpen && (
        <ModalDelete
          handleDelete={() => handleDeleteActor(id)}
          handleClose={() => setDeleteModalOpen(false)}
          name={name}
        />
      )}
      <tr key={id}>
        <td>{name}</td>
        <td>
          {category_joins?.length > 0
            ? category_joins.map(({category}) => `${category?.name}`).join(", ")
            : "brak"}
        </td>
        <td>{actor_videos.length}</td>
        <td>
          <Button
            type="edit"
            onClick={() => history.push(`heroes/${id}/basic_info`)}
          />
          <Button type="delete-bin" onClick={() => setDeleteModalOpen(true)} />
        </td>
      </tr>
    </>
  );
};

export default withRouter(ListItem);
