const initialState = {
  status: "invalid",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "USERS_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "USERS_SUCCESS":
      return {
        ...state,
        status: "success",
        ...action
      };
    case "USERS_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    default:
      return state;
  }
};
