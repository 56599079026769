import React from "react";

export default function MovieCategories() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            {/* <defs><style>.a{fill:none;}.b,.c{fill:#494b74;fill-rule:evenodd;}.b{opacity:0.3;}</style></defs> */}
            <rect fill='none' width="20" height="20" />
            <rect fill='none' width="20" height="20" />
            <path fill='#494b74' fillRule='evenodd' opacity='0.3' d="M4.833,5H16.5a.833.833,0,0,1,0,1.667H4.833A.833.833,0,0,1,4.833,5Zm0,6.667H16.5a.833.833,0,0,1,0,1.667H4.833a.833.833,0,0,1,0-1.667Z" transform="translate(-0.667 -0.833)" />
            <path fill='#494b74' fillRule='evenodd' d="M4.833,9H16.5a.833.833,0,0,1,0,1.667H4.833A.833.833,0,0,1,4.833,9Zm0,6.667H16.5a.833.833,0,0,1,0,1.667H4.833a.833.833,0,0,1,0-1.667Z" transform="translate(-0.667 -1.5)" />
        </svg>
    );

}