import React, { PureComponent } from "react";

class ModalPreview extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    setTimeout(() => {
      this.setState({ active: true });
    }, 200);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.handleClose();
    }
  }

  render() {
    const { src, alt, handleClose } = this.props;
    const { active } = this.state;

    return (
      <div className="popup-wrapper">
        <div
          className={`popup popup__image ${active ? "active" : ""}`}
          ref={(node) => (this.wrapperRef = node)}
        >
          <div className="popup__header">
            <button className="button" onClick={handleClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
                className="kt-svg-icon"
              >
                <g
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                    fill="#A2A5B9"
                  >
                    <rect x="0" y="7" width="16" height="2" rx="1" />
                    <rect
                      transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
                      x="0"
                      y="7"
                      width="16"
                      height="2"
                      rx="1"
                    />
                  </g>
                </g>
              </svg>
            </button>
          </div>
          <div className="popup__body">
            {src ? <img src={src} alt={alt || ""} /> : null}
          </div>
        </div>
      </div>
    );
  }
}

export default ModalPreview;
