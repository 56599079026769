import React, {useState} from "react";
import {withRouter} from "react-router-dom";
import {Button, ModalDelete} from "components";

const ListItem = ({id, name, section_videos, handleDelete, history}) => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  return (
    <>
      {isDeleteModalOpen && (
        <ModalDelete
          handleDelete={() => handleDelete(id)}
          handleClose={() => setDeleteModalOpen(false)}
          name={name}
        />
      )}
      <tr key={id}>
        <td>{name}</td>
        <td>{section_videos?.length}</td>
        <td>
          <Button type="edit" onClick={() => history.push(`home_page/${id}`)} />
          <Button type="delete-bin" onClick={() => setDeleteModalOpen(true)} />
        </td>
      </tr>
    </>
  );
};

export default withRouter(ListItem);
