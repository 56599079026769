import axios from "axios";
import Jsona from "jsona";
import { getCookie } from "./cookies";

const dataFormatter = new Jsona();

export const url =
  process.env.NODE_ENV === "production"
    ? "https://api.platformanauki.pl"
    : "https://api.platformanauki.pl";

const admin_url = `${url}/admin/v1/`;
const api_url = `${url}/api/v1/`;

axios.defaults.headers.common["Authorization"] = getCookie("token");

export const client = axios.create({ baseURL: admin_url });
export const api_client = axios.create({ baseURL: api_url });

export const apiFormatter = (data) => {
  return dataFormatter.deserialize(data);
};

export const handleErrors = (data) => {
  try {
    const errors = {};

    data.map(
      ({ detail, source: { pointer } }) =>
        (errors[pointer.split("/")[pointer.split("/").length - 1]] = detail)
    );
    return errors;
  } catch (error) {
    console.log(error);
    return [];
  }
};
