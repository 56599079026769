import React, {PureComponent} from "react";
import Button from "../../../components/Button";

class ListItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  render() {
    const {
      data,
      name,
      children,
      path,
      history,
      level,
    } = this.props;
    const {isOpen} = this.state;
    return (
      <li className="tree__item">
        <table className="table">
          {level !== 0 && (
            <div
              className="tree__line tree__line--horizontal"
              style={{left: `${level * 32 - 10}px`}}
            />
          )}
          <tbody className="table__body">
            <tr>
              <td
                style={{
                  paddingLeft: `${level === 0 ? 18 : level * 32 + 18}px`,
                }}
              >
                {children && children.length > 0 ? (
                  <>
                    <button
                      className="button button__tree"
                      onClick={() =>
                        this.setState((prevState) => ({
                          isOpen: !prevState.isOpen,
                        }))
                      }
                    >
                      {isOpen ? (
                        <svg
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="9"
                            height="9"
                            fill="white"
                            stroke="#5D78FF"
                          />
                          <line x1="3" y1="5" x2="7" y2="5" stroke="#5D78FF" />
                        </svg>
                      ) : (
                        <svg
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="9"
                            height="9"
                            fill="white"
                            stroke="#5D78FF"
                          />
                          <line x1="3" y1="5" x2="7" y2="5" stroke="#5D78FF" />
                          <line x1="5" y1="7" x2="5" y2="3" stroke="#5D78FF" />
                        </svg>
                      )}
                    </button>
                    <span className="tree-span tree-span--hide tree-span-after">
                      {name}
                    </span>
                  </>
                ) : (
                  name
                )}
              </td>
              {data.map((name, index) => (
                <td key={index} style={{width: `${100 / (data.length + 2)}%`}}>
                  {name}
                </td>
              ))}
              <td style={{width: `${100 / (data.length + 2)}%`}}>
                <Button type="edit" onClick={() => {}} />
              </td>
            </tr>
          </tbody>
        </table>
        {isOpen && children && children.length > 0 && (
          <ul className={`tree__list`}>
            <div
              className="tree__line tree__line--vertical"
              style={{
                left: `${level * 32}px`,
                height: children.length === 1 ? "60px" : "initial",
              }}
            />
            {children.map(
              ({
                id,
                attributes: {name, identifier, children, products_count},
              }) => (
                <ListItem
                  history={history}
                  key={id}
                  name={name}
                  path={path}
                  id={id}
                  data={[identifier, products_count]}
                  children={children}
                  level={level + 1}
                />
              )
            )}
          </ul>
        )}
      </li>
    );
  }
}

export default ListItem;
