import React, {useState} from "react";
import {withRouter} from "react-router-dom";
import {Button, ModalDelete} from "components";

const ListItem = ({
  id,
  name,
  duration,
  video_rating,
  category_joins,
  history,
  handleDelete,
}) => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  return (
    <>
      {isDeleteModalOpen && (
        <ModalDelete
          handleDelete={() => handleDelete(id)}
          handleClose={() => setDeleteModalOpen(false)}
          name={name}
        />
      )}
      <tr key={id}>
        <td>
          {name} {duration}
        </td>
        <td>
          {category_joins?.length > 0
            ? category_joins.map(({category}) => `${category?.name}`).join(", ")
            : "brak"}
        </td>
        <td>8</td>
        <td>{video_rating? video_rating.toFixed(2) : '-'}</td>
        <td>
          <Button
            type="edit"
            onClick={() => history.push(`/videos/${id}/basic_info`)}
          />
          <Button type="delete-bin" onClick={() => setDeleteModalOpen(true)} />
        </td>
      </tr>
    </>
  );
};

export default withRouter(ListItem);
