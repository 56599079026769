import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, ModalDelete } from "components";

const ListItem = ({ id, content, handleDelete, history, match: { params } }) => {
  const [is_modal_open, setDeleteModalOpen] = useState(false);

  return (
    <>
      {is_modal_open && (
        <ModalDelete
          handleDelete={() => handleDelete(id)}
          handleClose={() => setDeleteModalOpen(false)}
          name={`pytanie ${content}`}
        />
      )}
      <tr>
        <td>{content}</td>
        <td>
          <Button
            type="edit"
            onClick={() => {
              history.push(`/videos/${params.id}/questions/${id}`);
            }}
          />
          <Button type="delete-bin" onClick={() => setDeleteModalOpen(true)} />
        </td>
      </tr>
    </>
  );
};

export default withRouter(ListItem);
