import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { Main, AsyncMultiSelect,Checkbox, Input, ActionBar, Textarea, ImageUploader, AsyncSelect, Editor } from "components";
import { addMovie } from "actions/movies";
import { client } from "utils/api";
import { toBase64, jsonToFormData } from "utils/helpers";

const InputField = props => <Input {...props} />;
const TextareaField = props => <Textarea {...props} />;
const CheckField = props => <Checkbox {...props} />;

class MoviesNew extends PureComponent {
  state = {
    image_preview: null,
    image: null,
    image_hero_preview: null,
    image_hero: null,
    is_video_hero_selected: false,
    categories: null,
  };
  onSubmit = values => {
    const { categories, image, image_hero } = this.state;
    const { addMovie } = this.props;
    if (!!categories) {
      values.category_joins_attributes = categories.map(({ value }) => ({ category_id: value }));
    }
    if (!!image) {
      values.preview = image;
    }
    if (!!image_hero) {
      values.video_hero_attributes.preview = image_hero;
    }
    addMovie(jsonToFormData({ video: values })).then(id => id && this.props.history.push(`/videos/${id}/basic_info`));
  };
  loadCategoryOptions = q => {
    return new Promise(async (resolve, reject) => {
      try {
        const categories = await client.get(
          `categories?type=VideoCategory&sort[order]=asc&sort[column]=id&page=1&per_page=10${
            q ? `&q[name_matches]=%25${q}%25` : ""
          }`
        );
        const data = categories.data.data.map(({ id, attributes }) => ({
          label: attributes.name,
          value: id,
        }));
        resolve(data);
      } catch (error) {
        console.log(error);
        reject([]);
      }
    });
  };
  loadHeroOptions = q => {
    return new Promise(async (resolve, reject) => {
      try {
        const categories = await client.get(
          `actors?sort[order]=asc&sort[column]=id&page=1&per_page=10${q ? `&q[name_matches]=%25${q}%25` : ""}`
        );
        const data = categories.data.data.map(({ id, attributes }) => ({
          label: attributes.name,
          value: id,
        }));
        resolve(data);
      } catch (error) {
        console.log(error);
        reject([]);
      }
    });
  };
  handleUploadImage = file =>
    new Promise(async (resolve, reject) => {
      const image_preview = await toBase64(file);

      this.setState({ image: file, image_preview }, () => resolve());
    });
  handleUploadImageHero = file =>
    new Promise(async (resolve, reject) => {
      const image_hero_preview = await toBase64(file);

      this.setState({ image_hero: file, image_hero_preview }, () => resolve());
    });
  render() {
    const { handleSubmit, submiting, pristine, change } = this.props;
    const { image_preview, image_hero_preview, is_video_hero_selected } = this.state;
    return (
      <Main breadcrumbs={[{ name: "Dodaj film" }]} title="Dodaj film">
        <form noValidate onSubmit={handleSubmit(this.onSubmit)}>
          <div className="dashboard">
            <header className="dashboard__header">
              <div className="icon__container">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                  <rect fill="none" width="20" height="20" />
                  <circle
                    fill="#494b74"
                    opacity="0.3"
                    cx="8.333"
                    cy="8.333"
                    r="8.333"
                    transform="translate(1.667 1.667)"
                  />
                  <rect fill="#3699ff" width="1.667" height="5.833" rx="0.833" transform="translate(9.167 8.333)" />
                  <rect fill="#494b74" width="1.667" height="1.667" rx="0.833" transform="translate(9.167 5.833)" />
                </svg>
              </div>
              <h2 className="dashboard__title heading">Podstawowe informacje</h2>
            </header>
            <div className="dashboard__form">
              <div className="dashboard-wrapper-flex">
                <div className="column-half">
                  <Field label="Nazwa filmu *" name="name" component={InputField} />
                  <Field label="Link do filmu *" name="url" component={InputField} />
                  <AsyncMultiSelect
                    label="Wybierz kategorie"
                    getData={this.loadCategoryOptions}
                    onChange={categories => this.setState({ categories })}
                  />
                  <Field name="actor_video_attributes.actor_id" component={InputField} type="hidden" />
                  <AsyncSelect
                    label="Postać"
                    onChange={({ value }) => change("actor_video_attributes.actor_id", value)}
                    getData={this.loadHeroOptions}
                  />
                  <div className="form-group">
                    <div className="check-group">
                      <input
                        id="check_hero"
                        type="checkbox"
                        checked={is_video_hero_selected}
                        onChange={() =>
                          this.setState({
                            is_video_hero_selected: !is_video_hero_selected,
                          })
                        }
                      />
                      <label htmlFor="check_hero" className="label-form">
                        Oznacz ten film jako Video Hero
                      </label>
                    </div>
                  </div>
                  {is_video_hero_selected && (
                    <div className="form-row grid-2">
                      <ImageUploader
                        addText="dodaj zdjęcie"
                        label="Miniaturka"
                        url=""
                        images={image_hero_preview ? [{ path: image_hero_preview }] : []}
                        handleUpload={this.handleUploadImageHero}
                        handleDelete={() =>
                          this.setState({
                            image_hero_preview: null,
                            image_hero: null,
                          })
                        }
                        limit={1}
                      />
                      <Field label="Tag" name="video_hero_attributes.tag_name" component={InputField} />
                    </div>
                  )}
                </div>
                <div className="column-half">
                  <Field name="published" component={CheckField} label="Publikuj" />
                  <Field label="Intro" name="intro" minHeight={120} component={TextareaField} />
                  <div className="form-row grid-2">
                    <ImageUploader
                      addText="dodaj zdjęcie"
                      label="Miniaturka"
                      url=""
                      images={image_preview ? [{ path: image_preview }] : []}
                      handleUpload={this.handleUploadImage}
                      handleDelete={() =>
                        this.setState({
                          image_preview: null,
                          image: null,
                        })
                      }
                      limit={1}
                    />
                    <Field label="Czas trwania*" name="duration" component={InputField} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Editor label="Opis" name="description" value="" change={change} />
          <div className="dashboard">
            <header className="dashboard__header">
              <div className="icon__container">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                  <rect fill="none" width="20" height="20" />
                  <circle
                    fill="#494b74"
                    opacity="0.3"
                    cx="8.333"
                    cy="8.333"
                    r="8.333"
                    transform="translate(1.667 1.667)"
                  />
                  <rect fill="#3699ff" width="1.667" height="5.833" rx="0.833" transform="translate(9.167 8.333)" />
                  <rect fill="#494b74" width="1.667" height="1.667" rx="0.833" transform="translate(9.167 5.833)" />
                </svg>
              </div>
              <h2 className="dashboard__title heading">SEO</h2>
            </header>
            <div className="dashboard__form">
              <div className="form-row grid-2">
                <Field label="Meta title" name="seo_content_attributes.meta_title" component={InputField} />
                <Field label="Slug URL" name="seo_content_attributes.slug_url" component={InputField} />
              </div>
              <div className="form-row">
                <Field
                  label="Meta description"
                  name="seo_content_attributes.meta_description"
                  component={TextareaField}
                />
              </div>
            </div>
          </div>
          <ActionBar saveAction={handleSubmit(this.onSubmit)} disableSave={pristine || submiting} cancel="/" />
        </form>
      </Main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  addMovie: data => dispatch(addMovie(data)),
});

export default connect(
  null,
  mapDispatchToProps
)(reduxForm({ form: "movies_new", enableReinitialize: true })(withRouter(MoviesNew)));
