import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {Button} from "components";
import {withRouter} from "react-router-dom";
import ListItem from "./components/ListItem";
import queryString from "query-string";
import {Pagination} from "components";
import {Main, PageLoader} from "components";
import {getStaticPages, deleteStaticPage} from "actions/static_pages";

class Static extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      query: queryString.parse(this.props.location.search).q,
    };
  }

  componentDidMount() {
    const {
      location: {search},
      getStaticPages,
    } = this.props;
    const {page, per_page, q} = queryString.parse(search);
    getStaticPages(page, per_page, q);
  }

  handleDelete = (id) => {
    this.props
      .deleteStaticPage(id)
      .then(() => {
        this.props.getStaticPages();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const {history, location, static_pages, getStaticPages} = this.props;
    const {query} = this.state;

    if (
      !static_pages ||
      static_pages.status === "invalid" ||
      static_pages.status === "loading"
    )
      return <PageLoader />;

    if (static_pages.status === "failure") {
      return <p>Error</p>;
    }
    const {data, meta} = static_pages;
    return (
      <Main breadcrumbs={[{name: "Strony statyczne"}]} title="Strony statyczne">
        <div className="dashboard">
          <header className="dashboard__header">
            <div className="icon__container">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
              >
                <rect fill="none" width="20" height="20" />
                <rect
                  fill="#3699ff"
                  width="16"
                  height="4"
                  rx="1"
                  transform="translate(2 3)"
                />
                <rect
                  fill="#3699ff"
                  opacity="0.3"
                  width="16"
                  height="9"
                  rx="1"
                  transform="translate(2 9)"
                />
              </svg>
            </div>
            <h2 className="dashboard__title heading">
              Zarządzanie stronami statycznymi
            </h2>
            <Button
              type="add"
              text="Dodaj stronę"
              onClick={() => history.push("/static_pages/new")}
            />
          </header>
          {data?.length > 0 ? (
            <>
              <table className="table">
                <thead className="table__header table-head">
                  <tr>
                    <th>ID</th>
                    <th>Nazwa stronu</th>
                    <th>Slug - URL</th>
                    <th width="200">Działania</th>
                  </tr>
                </thead>
                <tbody className="table__body table-body">
                  {data.map((props) => (
                    <ListItem
                      key={props.id}
                      handleDelete={(id) => this.handleDelete(id)}
                      {...props}
                    />
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <div style={{padding: 20}}>
              <p>Brak wydań</p>
            </div>
          )}
          <Pagination
            data={{...meta, query}}
            history={history}
            location={location}
            action={(page, per_page) => getStaticPages(page, per_page, query)}
          />
        </div>
      </Main>
    );
  }
}

const mapStateToProps = ({static_pages}) => ({static_pages});

const mapDispatchToProps = (dispatch) => ({
  getStaticPages: (page, per_page, query, loading) =>
    dispatch(getStaticPages(page, per_page, query, loading)),
  deleteStaticPage: (id) => dispatch(deleteStaticPage(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Static));
