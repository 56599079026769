import React from "react";
import {
  NotFound,
  //
  HomeList,
  HomeNew,
  HomeEdit,
  //
  StaticPagesList,
  StaticPagesNew,
  StaticPagesEdit,
  //
  MoviesCategoriesList,
  MoviesCategoriesNew,
  MoviesCategoriesEdit,
  //
  MoviesList,
  MoviesNew,
  MoviesEdit,
  //
  ActorsCategoriesList,
  ActorsCategoriesNew,
  ActorsCategoriesEdit,
  //
  ActorsList,
  ActorsNew,
  ActorsEdit,
  //
  Info,
  //
  UsersList,
  UsersNew,
  UsersEdit,
  //
  Login,
} from "../pages";
import { Switch, Route, Redirect } from "react-router-dom";

const Protected = () => {
  return (
    <Switch>
      <Route exact={true} path="/login" component={Login} />
      <Route exact={true} path="/" component={() => <Redirect to="/home_page" />} />
      <Route exact={true} path="/home_page" component={HomeList} />
      <Route exact={true} path="/home_page/new" component={HomeNew} />
      <Route exact={true} path="/home_page/:id" component={HomeEdit} />
      <Route exact={true} path="/info_about_platform" component={Info} />
      <Route exact={true} path="/static_pages" component={StaticPagesList} />
      <Route exact={true} path="/static_pages/new" component={StaticPagesNew} />
      <Route exact={true} path="/static_pages/:id" component={StaticPagesEdit} />
      <Route exact={true} path="/movies_categories/new" component={MoviesCategoriesNew} />
      <Route exact={true} path="/movies_categories" component={MoviesCategoriesList} />
      <Route exact={true} path="/movies_categories/:id/:type" component={MoviesCategoriesEdit} />
      <Route exact={true} path="/videos" component={MoviesList} />
      <Route exact={true} path="/videos/new" component={MoviesNew} />
      <Route exact={true} path="/videos/:id/:type/:question_id*" component={MoviesEdit} />
      <Route exact={true} path="/actors_categories/new" component={ActorsCategoriesNew} />
      <Route exact={true} path="/actors_categories" component={ActorsCategoriesList} />
      <Route exact={true} path="/actors_categories/:id/:type" component={ActorsCategoriesEdit} />
      <Route exact={true} path="/heroes" component={ActorsList} />
      <Route exact={true} path="/heroes/new" component={ActorsNew} />
      <Route exact={true} path="/heroes/:id/:type*" component={ActorsEdit} />
      <Route exact={true} path="/users" component={UsersList} />
      <Route exact={true} path="/users/new" component={UsersNew} />
      <Route exact={true} path="/users/:id" component={UsersEdit} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Protected;
