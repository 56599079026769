import React from "react";
import {connect} from "react-redux";
import {Topbar, Breadcrumbs} from "components";

const Main = ({children, breadcrumbs, title}) => {
  return (
    <div className="main-dashboard">
      <Breadcrumbs data={breadcrumbs} />
      <Topbar title={title} />
      <div className="main-dashboard__container">{children}</div>
    </div>
  );
};

const mapStateToProps = ({sidebar}) => ({
  sidebar,
});

export default connect(mapStateToProps, null)(Main);
