import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Field, SubmissionError, reduxForm } from "redux-form";
import { Input, ActionBar, Textarea } from "components";
import { editMoviesCategory } from "actions/movies_categories";

const InputField = props => <Input {...props} />;
const TextareaField = props => <Textarea {...props} />;

class MoviesCategoriesBasicData extends PureComponent {
  onSubmit = values => {
    const {
      match: {
        params: { id },
      },
      editMoviesCategory,
    } = this.props;
    return editMoviesCategory(id, values).catch(err => {
      let errors = {};
      Object.keys(err).map(key => {
        if (key === "seo_content.slug_url") {
          errors = { ...errors, seo_content_attributes: { ...errors.seo_content_attributes, slug_url: err[key] } };
        } else {
          errors[key] = err[key];
        }
      });
      throw new SubmissionError(errors);
    });
  };

  render() {
    const { handleSubmit, submiting, pristine } = this.props;

    return (
      <>
        <div className="dashboard">
          <header className="dashboard__header">
            <div className="icon__container">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <rect fill="none" width="20" height="20" />
                <circle
                  fill="#494b74"
                  opacity="0.3"
                  cx="8.333"
                  cy="8.333"
                  r="8.333"
                  transform="translate(1.667 1.667)"
                />
                <rect fill="#3699ff" width="1.667" height="5.833" rx="0.833" transform="translate(9.167 8.333)" />
                <rect fill="#494b74" width="1.667" height="1.667" rx="0.833" transform="translate(9.167 5.833)" />
              </svg>
            </div>
            <h2 className="dashboard__title heading">Podstawowe informacje</h2>
          </header>
          <div className="dashboard__form">
            <form noValidate>
              <div className="form-row">
                <Field label="Nazwa kategorii *" name="name" component={InputField} />
              </div>
              <div className="form-row">
                <Field label="Opis *" name="seo_content_attributes.content" component={TextareaField} />
              </div>
            </form>
            <ActionBar saveAction={handleSubmit(this.onSubmit)} disableSave={pristine || submiting} cancel="/" />
          </div>
        </div>
        <div className="dashboard">
          <header className="dashboard__header">
            <div className="icon__container">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <rect fill="none" width="20" height="20" />
                <circle
                  fill="#494b74"
                  opacity="0.3"
                  cx="8.333"
                  cy="8.333"
                  r="8.333"
                  transform="translate(1.667 1.667)"
                />
                <rect fill="#3699ff" width="1.667" height="5.833" rx="0.833" transform="translate(9.167 8.333)" />
                <rect fill="#494b74" width="1.667" height="1.667" rx="0.833" transform="translate(9.167 5.833)" />
              </svg>
            </div>
            <h2 className="dashboard__title heading">SEO</h2>
          </header>
          <div className="dashboard__form">
            <form noValidate onSubmit={handleSubmit(this.onSubmit)}>
              <div className="form-row grid-2">
                <Field label="Meta title" name="seo_content_attributes.meta_title" component={InputField} />
                <Field label="Slug URL" name="seo_content_attributes.slug_url" component={InputField} />
              </div>
              <div className="form-row">
                <Field
                  label="Meta description"
                  name="seo_content_attributes.meta_description"
                  component={TextareaField}
                />
              </div>
            </form>
            <ActionBar saveAction={handleSubmit(this.onSubmit)} disableSave={pristine || submiting} cancel="/" />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ movies_category }) => ({
  movies_category,
  initialValues: {
    name: movies_category?.data?.name,
    seo_content_attributes: {
      ...movies_category?.data?.seo_content,
    },
  },
});

const mapDispatchToProps = dispatch => ({
  editMoviesCategory: (data, id) => dispatch(editMoviesCategory(data, id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "movies_categories_edit", enableReinitialize: true })(withRouter(MoviesCategoriesBasicData)));
