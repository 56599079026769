import React from "react";

export default function Dashboard() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <rect fill="none" width="20" height="20" />
            <path fill='#494b74' fillRule='evenodd' opacity='0.3' d="M5,7v6.667H16.667V7Z" transform="translate(-0.833 -1.167)" />
            <path fill="#494b74" fillRule='evenodd' opacity='0.3' d="M10.667,18.333v-2.5a.833.833,0,0,1,1.667,0v2.5h1.25a.417.417,0,1,1,0,.833H9.417a.417.417,0,0,1,0-.833Z" transform="translate(-1.5 -2.5)" />
            <path fill="#494b74" d="M4.667,6.667v6.667H16.333V6.667ZM4.875,5h11.25A1.78,1.78,0,0,1,18,6.667v6.667A1.78,1.78,0,0,1,16.125,15H4.875A1.78,1.78,0,0,1,3,13.333V6.667A1.78,1.78,0,0,1,4.875,5Z" transform="translate(-0.5 -0.833)" />
        </svg>
    );

}