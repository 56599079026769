import React, {PureComponent} from "react";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Main} from "components";
import {Field, reduxForm} from "redux-form";
import {Button, Input} from "components";

const InputField = (props) => <Input {...props} />;

class MoviesCategoriesEdit extends PureComponent {
  onSubmit = (values) => {
    console.log(values);
  };
  render() {
    const {
      handleSubmit,
      match: {
        params: {type},
      },
    } = this.props;

    return (
      <Main breadcrumbs={[{name: "Kategorie filmowe"}, {name: "KATEGORIA"}]}>
        <ul className="dashboard__tabs">
          <li
            className={`tab dashboard-tab-text ${type === "basic_info" &&
              "active"}`}
          >
            <Link to="basic_info">Podstawowe informacje</Link>
          </li>
          <li
            className={`tab dashboard-tab-text ${type === "actors" &&
              "active"}`}
          >
            <Link to="actors">Bohaterowie</Link>
          </li>
        </ul>
        {type === "basic_info" ? (
          <div className="dashboard-wrapper-flex">
            <div className="column-half">
              <div className="dashboard">
                <header className="dashboard__header">
                  <h2 className="dashboard__title heading">
                    Podstawowe informacje
                  </h2>
                  <Button type="cancel" text="Anuluj" />
                  <Button
                    type="save"
                    style={{marginLeft: "15px"}}
                    text="Zapisz"
                  />
                </header>
                <div className="dashboard__form">
                  <form noValidate onSubmit={handleSubmit(this.onSubmit)}>
                    <div className="form-row">
                      <Field
                        label="Nazwa kategorii *"
                        name="name"
                        component={InputField}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="dashboard">
            <header className="dashboard__header">
              <div className="icon__container">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                >
                  <rect fill="none" d="M0,0H20V20H0Z" />
                  <path
                    opacity="0.3"
                    fill="#494b74"
                    d="M15.833,12.167a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,15.833,12.167Zm-7.5-2.5a3.333,3.333,0,1,1,3.333-3.333A3.333,3.333,0,0,1,8.333,9.667Z"
                    transform="translate(-0.833 -0.5)"
                  />
                  <path
                    fill="#494b74"
                    d="M14.668,14.667c2.839.031,5.157,1.466,5.331,4.5a.425.425,0,0,1-.452.5H16.333A8.3,8.3,0,0,0,14.668,14.667ZM0,19c.323-3.977,3.551-6,7.486-6,3.99,0,7.268,1.911,7.512,6a.572.572,0,0,1-.626.667H.606A.923.923,0,0,1,0,19Z"
                    transform="translate(0 -2.167)"
                  />
                </svg>
              </div>
              <h2 className="dashboard__title heading">
                Bohaterowie przypisani do kategorii
              </h2>
            </header>
            {/* {data?.length > 0 ? ( */}
            <>
              <table className="table">
                <thead className="table__header table-head">
                  <tr>
                    <th>Bohater</th>
                    <th>Liczba filmów</th>
                  </tr>
                </thead>
                <tbody className="table__body table-body">
                  <tr>
                    <td>sd</td>
                    <td>asd</td>
                  </tr>
                </tbody>
              </table>
              {/* <Pagination
                              data={{ ...meta, query }}
                              history={history}
                              location={location}
                              action={(page, per_page) => getGdns(page, per_page, query)}
                            /> */}
            </>
            {/* ) : (
                            <div style={{padding: 20}}>
                              <p>Brak wydań</p>
                            </div>
                          )} */}
          </div>
        )}
      </Main>
    );
  }
}

// const mapStateToProps = ({ edit_grn }) => ({
//   edit_grn,
// });

const mapDispatchToProps = (dispatch) => ({
  // getGrn: (id) => dispatch(getGrn(id)),
});

export default connect(
  null,
  mapDispatchToProps
)(
  reduxForm({form: "movies_categories", enableReinitialize: true})(
    withRouter(MoviesCategoriesEdit)
  )
);
