import React, { useState } from "react";
import { Button, ModalDelete } from "components";

const ListItem = ({ id, content, user, rating, created_at, handleDelete }) => {
  const [is_modal_open, setDeleteModalOpen] = useState(false);
  return (
    <>
      {is_modal_open && (
        <ModalDelete
          handleDelete={() => handleDelete(id)}
          handleClose={() => setDeleteModalOpen(false)}
          name={"komentarz"}
        />
      )}
      <tr key={id}>
        <td>
          <div className="comment">
            <div className="comment__image">
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                <path fill="none" d="M0,0H28V28H0Z" />
                <path
                  fill="#3699ff"
                  opacity="0.3"
                  d="M12.667,12.333a4.667,4.667,0,1,1,4.667-4.667A4.667,4.667,0,0,1,12.667,12.333Z"
                  transform="translate(1.333 0.5)"
                />
                <path
                  fill="#3699ff"
                  d="M3,21.4c.452-5.568,4.971-8.4,10.48-8.4C19.066,13,23.656,15.675,24,21.4a.8.8,0,0,1-.876.933H3.849A1.292,1.292,0,0,1,3,21.4Z"
                  transform="translate(0.5 2.167)"
                />
              </svg>
            </div>
            <div className="comment__text">
              <p className="user">{user?.name}</p>
              <p className="content">{content}</p>
            </div>
          </div>
        </td>
        <td>
          {created_at
            ? created_at
                .split("T")[0]
                .split("-")
                .reverse()
                .join("-")
            : ""}
        </td>
        <td>{rating || 0}</td>
        <td>
          <Button type="delete-bin" onClick={() => setDeleteModalOpen(true)} />
        </td>
      </tr>
    </>
  );
};

export default ListItem;
