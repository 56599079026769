import { toastr } from "react-redux-toastr";
import { client, apiFormatter } from "utils/api";

export const getStaticPages = (page = 1, per_page = 10, query = null, loading = true) => async dispatch => {
  loading && dispatch({ type: "STATIC_PAGES_LOADING" });
  await client
    .get(
      `static_pages?include=seo_content&sort[order]=asc&sort[column]=id&page=${page}&per_page=${per_page}${
        query ? `&q[name_matches]=%25${query}%25` : ""
      }`
    )
    .then(({ data }) => {
      dispatch({
        type: "STATIC_PAGES_SUCCESS",
        data: apiFormatter(data),
        meta: data.meta,
      });
    })
    .catch(() => {
      dispatch({ type: "STATIC_PAGES_FAILURE" });
      toastr.error("Błąd", "Wystąpił błąd podzczas pobierania");
    });
};

export const getStaticPage = id => async dispatch => {
  dispatch({ type: "STATIC_PAGE_LOADING" });
  await client
    .get(`static_pages/${id}?include=seo_content`)
    .then(({ data }) => {
      dispatch({
        type: "STATIC_PAGE_SUCCESS",
        data: apiFormatter(data),
      });
    })
    .catch(() => {
      dispatch({ type: "STATIC_PAGE_FAILURE" });
      toastr.error("Błąd", "Wystąpił błąd podzczas pobierania");
    });
};

export const addStaticPage = data => dispatch =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await client.post(`/static_pages`, data);
      toastr.success("Sukces", "Sekcja została dodana");
      resolve(res?.data?.data?.id);
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      reject();
    }
  });

export const editStaticPage = (id, data) => dispatch =>
  new Promise(async (resolve, reject) => {
    try {
      await client.put(`/static_pages/${id}?include=seo_content`, data);
      toastr.success("Sukces", "Sekcja została zedytowana");
      resolve();
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      reject();
    }
  });

export const deleteStaticPage = (id, data) => dispatch =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/static_pages/${id}`);
      toastr.success("Sukces", "Strona statyczna została usunięta");
      resolve();
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      reject();
    }
  });
