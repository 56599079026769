import React from "react";

export default function ActorCategories() {
    return (

        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            {/* <defs><style>.a{fill:none;}.b,.c{fill:#494b74;fill-rule:evenodd;}.c{opacity:0.3;}</style></defs> */}
            <rect fill='none' width="20" height="20" />
            <path fill='#494b74' fillRule='evenodd' d="M10.254,5h7.525a1.254,1.254,0,1,1,0,2.508H10.254a1.254,1.254,0,1,1,0-2.508Zm0,4.181h7.525a1.254,1.254,0,0,1,0,2.508H10.254a1.254,1.254,0,0,1,0-2.508Zm0,4.181h7.525a1.254,1.254,0,0,1,0,2.508H10.254a1.254,1.254,0,0,1,0-2.508Z" transform="translate(-1.475 -0.819)" />
            <path fill='#494b74' fillRule='evenodd' opacity='0.3' d="M5.254,7.508A1.254,1.254,0,1,1,6.508,6.254,1.254,1.254,0,0,1,5.254,7.508Zm0,4.181a1.254,1.254,0,1,1,1.254-1.254A1.254,1.254,0,0,1,5.254,11.689Zm0,4.181a1.254,1.254,0,1,1,1.254-1.254A1.254,1.254,0,0,1,5.254,15.87Z" transform="translate(-0.655 -0.819)" />
        </svg>
    );

}