import React, {useEffect, useState} from "react";
import axios from "axios";
import {PageLoader, Sidebar} from "components";
import {Auth, Protected} from "../routes";
import {getCookie} from "utils/cookies";
const App = () => {
  const [loading, setLoading] = useState(true);
  const [is_protected, setProtect] = useState(false);
  useEffect(() => {
    const token = getCookie("token");
    if (!!token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${getCookie(
        "token"
      )}`;
      setProtect(true);
    }
    setLoading(false);
  }, []);
  if (loading) return <PageLoader />;
  if (!is_protected) {
    return <Auth />;
  }
  return (
    <>
      <Sidebar />
      <main className="main-container">
        <Protected />
      </main>
    </>
  );
};



export default App;
