import React, {Component} from "react";
import New from "./components/New";
import Edit from "./components/Edit";
import ModalDelete from "../../components/ModalDelete";
import ModalUpload from "../../components/ModalUpload";
class ImageUploader extends Component {
  state = {
    showDeleteLogoModal: false,
    showAddLogoModal: false,
  };
  render() {
    const {
      images = [],
      limit = 1,
      addText = "dodaj",
      handleDelete,
      handleUpload,
      url,
      label,
    } = this.props;
    return (
      <div>
        {this.state.showDeleteLogoModal && (
          <ModalDelete
            handleDelete={() =>
              handleDelete().then(() =>
                this.setState({showDeleteLogoModal: false})
              )
            }
            handleClose={() => this.setState({showDeleteLogoModal: false})}
            name={`logo`}
          />
        )}
        {this.state.showAddLogoModal && (
          <ModalUpload
            headerText="Prześlij logo"
            handleUpload={handleUpload}
            handleClose={() => this.setState({showAddLogoModal: false})}
            name={`logo`}
            limit={limit}
          />
        )}
        {label && <p className="image-uploader__label">{label}</p>}
        <div className="image-uploader">
          {images.length > 0 &&
            images.map(
              ({path}, index) =>
                path && (
                  <Edit
                    showDelete={!!handleDelete}
                    showEdit={!!handleUpload}
                    handleOpenDeleteModal={() =>
                      this.setState({showDeleteLogoModal: true})
                    }
                    handleOpenEditModal={() =>
                      this.setState({showAddLogoModal: true})
                    }
                    key={index}
                    url={url}
                    path={path}
                  />
                )
            )}
          {images.length < limit && (
            <New
              addText={addText}
              handleOpenEditModal={() =>
                this.setState({showAddLogoModal: true})
              }
            />
          )}
        </div>
      </div>
    );
  }
}

export default ImageUploader;
