import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import {  Pagination,PageLoader } from "components";
import { getMovieCategoryMovies } from "actions/movies_categories";
import { url } from "utils/api";

class MoviesCategoriesBasicDataMovies extends PureComponent {
  componentDidMount() {
    const {
      match,
      location: { search },
      getMovieCategoryMovies,
    } = this.props;
    const { page, per_page } = queryString.parse(search);
    getMovieCategoryMovies(page, per_page, match.params.id);
  }

  handleDeleteActor = id => {
    this.props
      .deleteActor(id)
      .then(() => {
        this.props.getActors();
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    const { history, location, movie_category_movies, getMovieCategoryMovies, match } = this.props;

    if (
      !movie_category_movies ||
      movie_category_movies.status === "invalid" ||
      movie_category_movies.status === "loading"
    )
      return <PageLoader />;

    if (movie_category_movies.status === "failure") {
      return <p>Error</p>;
    }
    const { data, meta } = movie_category_movies;
    return (
      <div className="dashboard">
        <header className="dashboard__header">
          <div className="icon__container">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
              <rect fill="none" fillRule="evenodd" d="M0,0H20V20H0Z" />
              <path
                fill="#494b74"
                opacity="0.3"
                d="M5.548,2h6.566a1.25,1.25,0,0,1,.807.3l3.97,3.358a1.25,1.25,0,0,1,.443.954V17.069c0,1.492-.017,1.6-1.548,1.6H5.548c-1.531,0-1.548-.105-1.548-1.6V3.6C4,2.105,4.017,2,5.548,2Z"
                transform="translate(-0.667 -0.333)"
              />
              <path
                fill="#494b74"
                fillRule="evenodd"
                d="M10.652,14.647l3.67-2.508a.417.417,0,0,0,0-.687l-3.67-2.524A.417.417,0,0,0,10,9.27V14.3a.417.417,0,0,0,.652.344Z"
                transform="translate(-1.667 -1.476)"
              />
            </svg>
          </div>
          <h2 className="dashboard__title heading">Filmy przypisane do kategorii</h2>
        </header>

        {data?.length > 0 ? (
          <>
            <table className="table">
              <thead className="table__header table-head">
                <tr>
                  <th>Film</th>
                  <th width="300">Wyświetlenia</th>
                  <th width="200">Ocena</th>
                </tr>
              </thead>
              <tbody className="table__body table-body">
                {data.map(({ id, name, duration, preview_url, video_rating }) => (
                  <tr key={id} style={{ cursor: "pointer" }} onClick={() => history.push(`/videos/${id}/basic_info`)}>
                    <td>
                      <div className="video-box">
                        <div className="image" style={{ backgroundImage: `url(${url}${preview_url})` }} />
                        <div className="text">
                          <h3>{name}</h3>
                          <p>{duration}</p>
                        </div>
                      </div>
                    </td>
                    <td>8</td>
                    <td>{video_rating ? video_rating.toFixed(2) : "="}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : (
          <div style={{ padding: 20 }}>
            <p>Brak filmów</p>
          </div>
        )}
        <Pagination
          data={meta}
          history={history}
          location={location}
          action={(page, per_page) => getMovieCategoryMovies(page, per_page, match.params.id)}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ movie_category_movies }) => ({ movie_category_movies });

const mapDispatchToProps = dispatch => ({
  getMovieCategoryMovies: (page, per_page, id) => dispatch(getMovieCategoryMovies(page, per_page, id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MoviesCategoriesBasicDataMovies));
