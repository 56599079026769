import {toastr} from "react-redux-toastr";
import {client, apiFormatter} from "utils/api";

export const getActors = (
  page = 1,
  per_page = 10,
  query = null,
  loading = true
) => async (dispatch) => {
  loading && dispatch({type: "ACTORS_LOADING"});
  await client
    .get(
      `actors?include=category_joins.category&sort[order]=asc&sort[column]=id&page=${page}&per_page=${per_page}${
        query ? `&q[name_matches]=%25${query}%25` : ""
      }`
    )
    .then(({data}) => {
      dispatch({
        type: "ACTORS_SUCCESS",
        data: apiFormatter(data),
        meta: data.meta,
      });
    })
    .catch(() => {
      dispatch({type: "ACTORS_FAILURE"});
      toastr.error("Błąd", "Wystąpił błąd podzczas pobierania");
    });
};

export const getActor = (id) => async (dispatch) => {
  dispatch({type: "ACTOR_LOADING"});
  await client
    .get(
      `actors/${id}?include=seo_content,category_joins.category,actor_videos.video`
    )
    .then(({data}) => {
      dispatch({
        type: "ACTOR_SUCCESS",
        data: apiFormatter(data),
      });
    })
    .catch(() => {
      dispatch({type: "ACTOR_FAILURE"});
      toastr.error("Błąd", "Wystąpił błąd podzczas pobierania");
    });
};

export const deleteActor = (id, data) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/actors/${id}`);
      toastr.success("Sukces", "Aktor został usunięty");
      resolve();
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      reject();
    }
  });

export const addActor = (data) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await client.post(`/actors`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toastr.success("Sukces", "Aktor został dodany");
      resolve(res?.data?.data?.id);
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      reject();
    }
  });

export const editActor = (id, data) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await client.put(
        `/actors/${id}?include=seo_content,category_joins.category,actor_videos.video`,
        data
      );
      toastr.success("Sukces", "Aktor został zaktualizowany");
      dispatch({
        type: "ACTOR_SUCCESS",
        data: apiFormatter(res.data),
      });
      resolve();
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      reject();
    }
  });
