import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {Main} from "components";
import {Field, reduxForm} from "redux-form";
import {Input, ActionBar} from "components";

const InputField = (props) => <Input {...props} />;

class Info extends PureComponent {
  onSubmit = (values) => {
    console.log(values);
  };
  render() {
    const {handleSubmit, submiting, pristine} = this.props;
    return (
      <Main
        breadcrumbs={[{name: "Informacje o platformie"}]}
        title="Informacje o platformie"
      >
        <div className="dashboard">
          <header className="dashboard__header">
            <div className="icon__container">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
              >
                <rect fill="none" width="20" height="20" />
                <circle
                  fill="#494b74"
                  opacity="0.3"
                  cx="8.333"
                  cy="8.333"
                  r="8.333"
                  transform="translate(1.667 1.667)"
                />
                <rect
                  fill="#3699ff"
                  width="1.667"
                  height="5.833"
                  rx="0.833"
                  transform="translate(9.167 8.333)"
                />
                <rect
                  fill="#494b74"
                  width="1.667"
                  height="1.667"
                  rx="0.833"
                  transform="translate(9.167 5.833)"
                />
              </svg>
            </div>
            <h2 className="dashboard__title heading">Dane kontaktowe</h2>
          </header>
          <div className="dashboard__form">
            <form noValidate onSubmit={handleSubmit(this.onSubmit)}>
              <div className="form-row grid-2">
                <Field
                  label="Adres e-mail *"
                  name="email"
                  component={InputField}
                />
                <Field
                  label="Inne dane kontaktowe"
                  name="other"
                  component={InputField}
                />
              </div>
            </form>
            <ActionBar
              saveAction={handleSubmit(this.onSubmit)}
              disableSave={pristine || submiting}
              cancel="/"
            />
          </div>
        </div>
        <div className="dashboard">
          <header className="dashboard__header">
            <div className="icon__container">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
              >
                <rect fill="none" width="20" height="20" />
                <path
                  fill="#3699ff"
                  d="M0,13.262V3.306a1.25,1.25,0,0,1,.375-.893L2.471.357A1.25,1.25,0,0,1,4.208.344l2.07,1.969a1.25,1.25,0,0,1,.389.906V13.262a1.25,1.25,0,0,1-1.25,1.25H1.25A1.25,1.25,0,0,1,0,13.262Z"
                  transform="translate(7.226 16.411) rotate(-135)"
                />
                <rect
                  fill="#3699ff"
                  opacity="0.3"
                  width="13"
                  height="1"
                  rx="0.5"
                  transform="translate(4 17)"
                />
              </svg>
            </div>
            <h2 className="dashboard__title heading editor">Edytor tekstu</h2>
            <span className="actualize">
              Ostatnia aktualizacja: 04-06-2020, 16:37
            </span>
          </header>
        </div>
      </Main>
    );
  }
}

// const mapStateToProps = ({ edit_grn }) => ({
//   edit_grn,
// });

const mapDispatchToProps = (dispatch) => ({
  // getGrn: (id) => dispatch(getGrn(id)),
});

export default connect(
  null,
  mapDispatchToProps
)(reduxForm({form: "info_about_platform", enableReinitialize: true})(Info));
