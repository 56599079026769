import React from "react";
import {withRouter} from "react-router-dom";
import {Main} from "components";
import {Field, reduxForm} from "redux-form";
import {Button, Input} from "components";
import {createActorCategory} from "actions/actors_categories";

const InputField = (props) => <Input {...props} />;

const ActorsCategoriesNew = ({pristine, history, handleSubmit}) => {
  const onSubmit = (values) => {
    createActorCategory(values)
      .then(({data}) => history.push(`/actors_categories/${data.id}`))
      .catch((err) => console.log(err));
  };
  return (
    <Main
      breadcrumbs={[{name: "Dodaj kategorie bohaterów"}]}
      title="Dodaj kategorie bohaterów"
    >
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <div className="dashboard">
            <header className="dashboard__header">
              <h2 className="dashboard__title heading">
                Podstawowe informacje
              </h2>
              <Button
                type="cancel"
                text="Anuluj"
                onClick={() => history.push("/actors_categories")}
              />
              <Button
                type="save"
                style={{marginLeft: "15px"}}
                disabled={pristine}
                text="Zapisz"
                onClick={handleSubmit(onSubmit)}
              />
            </header>
            <div className="dashboard__form">
              <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row">
                  <Field
                    label="Nazwa kategorii*"
                    name="name"
                    component={InputField}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default reduxForm({
  form: "actors-categories-new",
  enableReinitialize: true,
})(withRouter(ActorsCategoriesNew));
