import { toastr } from "react-redux-toastr";
import { client, apiFormatter } from "utils/api";

export const getQuestions = id => async dispatch => {
  try {
    dispatch({ type: "QUESTIONS_LOADING" });
    const { data } = await client.get(
      `forms/${id}/questions?sort[order]=asc&sort[column]=id&per_page=100&include=question_options`
    );
    dispatch({
      type: "QUESTIONS_SUCCESS",
      data: apiFormatter(data),
      meta: data.meta,
    });
  } catch (error) {
    dispatch({ type: "QUESTIONS_FAILURE" });
    toastr.error("Błąd", "Wystąpił błąd podzczas pobierania");
  }
};

export const addQuestion = values => (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    const {
      movie: {
        data: {
          form: { id },
        },
      },
    } = getState();
    try {
      const { data } = await client.post(`forms/${id}/questions?include=question_options`, values);
      toastr.success("Sukces", "Pytanie zostało dodane");

      dispatch({
        type: "ADD_QUESTION",
        data: apiFormatter(data),
      });
      resolve();
    } catch (error) {
      reject();
      toastr.error("Błąd", "Wystąpił błąd podzczas dodawania");
    }
  });

export const updateQuestion = (id, values) => (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.put(`questions/${id}?include=question_options`, values);
      toastr.success("Sukces", "Pytanie zostało zaktualizowane");
      dispatch({
        type: "UPDATE_QUESTION",
        data: apiFormatter(data),
      });
      resolve();
    } catch (error) {
      reject();
      toastr.error("Błąd", "Wystąpił błąd podzczas dodawania");
    }
  });

export const deleteQuestion = id => dispatch =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`questions/${id}`);
      toastr.success("Sukces", "Pytanie zostało usunięte");
      dispatch({
        type: "DELETE_QUESTION",
        id,
      });
      resolve();
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      reject();
    }
  });

export const updateForm = (id, values) => dispatch =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.put(`forms/${id}`, values);
      toastr.success("Sukces", "Formularz został zaktualizowany");
      dispatch({
        type: "UPDATE_FORM_TIME",
        estimated_time: apiFormatter(data).estimated_time,
      });
      resolve();
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      reject();
    }
  });
