import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { Input, Textarea, Button } from "components";
import { updateQuestion } from "actions/questions";
import validate from "./validate";

const answers = [
  { label: "A", value: 0 },
  { label: "B", value: 1 },
  { label: "C", value: 2 },
  { label: "D", value: 3 },
];

const InputField = props => <Input {...props} />;
const TextareaField = props => <Textarea {...props} />;

const EditQuestion = ({
  pristine,
  dispatch,
  handleSubmit,
  invalid,
  change,
  history,
  match: {
    params: { id, question_id },
  },
  initialValues,
  updateQuestion,
}) => {
  const [correct_value, setCorrectValue] = useState(initialValues.correct);
  const onSubmit = values => {
    const correct = values.correct;
    delete values.correct;
    const data = {
      ...values,
      question_options_attributes: values.question_options_attributes.map((item, index) => ({
        ...item,
        correct: index === correct,
      })),
    };
    updateQuestion(question_id, data);
  };
  return (
    <div className="dashboard">
      <header className="dashboard__header">
        <h2 className="dashboard__title heading">Edytuj pytanie</h2>
        <Button style={{ marginRight: 15 }} onClick={() => history.push(`/videos/${id}/questions`)} type="cancel" />
        <Button disabled={pristine || invalid} onClick={handleSubmit(onSubmit)} type="save" text="Zapisz" />
      </header>
      <div className="dashboard__form">
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Field label="Nowe pytanie*" name="content" component={TextareaField} />
          <h2 className="label-form answers">
            Odpowiedzi: <span>Oznacz poprawną odpowiedź*</span>
          </h2>
          <Field name="correct" component={InputField} type="hidden" />
          {answers.map(({ label, value }) => (
            <div className="answer-wrapper" key={value}>
              <div className="answer-content">
                <Field label={label} name={`question_options_attributes[${value}].content`} component={InputField} />
              </div>
              <div className="answer-correct">
                <input
                  type="checkbox"
                  id={`correnct[${value}]`}
                  checked={correct_value === value}
                  onChange={() => {
                    change("correct", value);
                    setCorrectValue(value);
                  }}
                />
                <label htmlFor={`correnct[${value}]`} />
              </div>
            </div>
          ))}
        </form>
      </div>
    </div>
  );
};
const mapDispatchToProps = dispatch => ({
  updateQuestion: (id, data) => dispatch(updateQuestion(id, data)),
});

export default connect(
  null,
  mapDispatchToProps
)(reduxForm({ enableReinitialize: true, validate })(withRouter(EditQuestion)));
