const initialState = {
  status: "invalid",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "MOVIE_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "MOVIE_SUCCESS":
      return {
        ...state,
        status: "success",
        ...action,
      };
    case "MOVIE_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    case "UPDATE_FORM_TIME":
      return {
        ...state,
        data: { ...state.data, form: { ...state.data.form, estimated_time: action.estimated_time } },
      };

    default:
      return state;
  }
};
