import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Main, PageLoader} from "components";
import {Field, reduxForm} from "redux-form";
import {Input, ActionBar, Textarea, Editor} from "components";
import {editStaticPage, getStaticPage} from "actions/static_pages";

const InputField = (props) => <Input {...props} />;
const TextareaField = (props) => <Textarea {...props} />;

class StaticEdit extends PureComponent {
  componentDidMount() {
    this.getStaticPage();
  }

  getStaticPage() {
    const {
      match: {
        params: {id},
      },
    } = this.props;
    this.props.getStaticPage(id);
  }

  onSubmit = (values) => {
    const {
      match: {
        params: {id},
      },
      editStaticPage,
    } = this.props;

    editStaticPage(id, values);
    getStaticPage();
  };

  render() {
    const {static_page, initialValues, change} = this.props;

    if (
      !static_page ||
      static_page.status === "invalid" ||
      static_page.status === "loading"
    )
      return <PageLoader />;

    if (static_page.status === "failure") {
      return <p>Error</p>;
    }

    const {handleSubmit, submiting, pristine} = this.props;
    return (
      <Main
        breadcrumbs={[{name: "Edytuj stronę statyczną"}]}
        title={static_page?.data?.name}
      >
        <div className="dashboard">
          <header className="dashboard__header">
            <div className="icon__container">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
              >
                <rect fill="none" width="20" height="20" />
                <circle
                  fill="#494b74"
                  opacity="0.3"
                  cx="8.333"
                  cy="8.333"
                  r="8.333"
                  transform="translate(1.667 1.667)"
                />
                <rect
                  fill="#3699ff"
                  width="1.667"
                  height="5.833"
                  rx="0.833"
                  transform="translate(9.167 8.333)"
                />
                <rect
                  fill="#494b74"
                  width="1.667"
                  height="1.667"
                  rx="0.833"
                  transform="translate(9.167 5.833)"
                />
              </svg>
            </div>
            <h2 className="dashboard__title heading">Podstawowe informacje</h2>
          </header>
          <div className="dashboard__form">
            <div className="form-row">
              <Field
                label="Nazwa strony statycznej"
                name="name"
                component={InputField}
              />
            </div>
          </div>
        </div>
        <Editor
          name="content"
          value={initialValues.content}
          label="Opis"
          change={change}
        />
        <div className="dashboard">
          <header className="dashboard__header">
            <div className="icon__container">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
              >
                <rect fill="none" width="20" height="20" />
                <circle
                  fill="#494b74"
                  opacity="0.3"
                  cx="8.333"
                  cy="8.333"
                  r="8.333"
                  transform="translate(1.667 1.667)"
                />
                <rect
                  fill="#3699ff"
                  width="1.667"
                  height="5.833"
                  rx="0.833"
                  transform="translate(9.167 8.333)"
                />
                <rect
                  fill="#494b74"
                  width="1.667"
                  height="1.667"
                  rx="0.833"
                  transform="translate(9.167 5.833)"
                />
              </svg>
            </div>
            <h2 className="dashboard__title heading">SEO</h2>
          </header>
          <div className="dashboard__form">
            <div className="form-row grid-2">
              <Field
                label="Meta title"
                name="seo_content_attributes.meta_title"
                component={InputField}
              />
              <Field
                label="Slug URL"
                name="seo_content_attributes.slug_url"
                component={InputField}
              />
            </div>
            <div className="form-row">
              <Field
                label="Meta description"
                name="seo_content_attributes.meta_description"
                component={TextareaField}
              />
            </div>
          </div>
        </div>
        <ActionBar
          saveAction={handleSubmit(this.onSubmit)}
          disableSave={pristine || submiting}
          cancel="/static_pages"
        />
      </Main>
    );
  }
}

const mapStateToProps = ({static_page}) => ({
  static_page,
  initialValues: {
    name: static_page?.data?.name,
    content: static_page?.data?.content,
    seo_content_attributes: {
      slug_url: static_page?.data?.seo_content?.slug_url,
      meta_title: static_page?.data?.seo_content?.meta_title,
      meta_description: static_page?.data?.seo_content?.meta_description,
    },
  },
});

const mapDispatchToProps = (dispatch) => ({
  editStaticPage: (data, id) => dispatch(editStaticPage(data, id)),
  getStaticPage: (id) => dispatch(getStaticPage(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({form: "static_edit", enableReinitialize: true})(
    withRouter(StaticEdit)
  )
);
