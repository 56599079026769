import React, { PureComponent } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Main, PageLoader } from "components";
import { getMoviesCategory } from "actions/movies_categories";
import { BasicData, Movies } from "./components";
class MoviesCategoriesEdit extends PureComponent {
  componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.props.getMoviesCategory(id);
  }

  render() {
    const { movies_category } = this.props;

    if (!movies_category || movies_category.status === "invalid" || movies_category.status === "loading")
      return <PageLoader />;

    if (movies_category.status === "failure") {
      return <p>Error</p>;
    }
    const {

      match: {
        params: { type },
      },
    } = this.props;

    return (
      <Main
        breadcrumbs={[{ name: "Kategorie filmowe" }, { name: movies_category?.data?.name }]}
        title={movies_category?.data?.name}
      >
        <ul className="dashboard__tabs">
          <li className={`tab dashboard-tab-text ${type === "basic_info" && "active"}`}>
            <Link to="basic_info">Podstawowe informacje</Link>
          </li>
          <li className={`tab dashboard-tab-text ${type === "movies" && "active"}`}>
            <Link to="movies">Filmy</Link>
          </li>
        </ul>
        {type === "basic_info" ? <BasicData /> : type === "movies" ? <Movies /> : null}
      </Main>
    );
  }
}

const mapStateToProps = ({ movies_category }) => ({
  movies_category,
});

const mapDispatchToProps = dispatch => ({
  getMoviesCategory: id => dispatch(getMoviesCategory(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MoviesCategoriesEdit));
