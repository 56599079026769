import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Main, PageLoader } from "components";
import { getMovie } from "actions/movies";
import { BasicData, Comments, FormQuest } from "./components";

const tabs = [
  { id: "basic_info", label: "Podstawowe informacje" },
  { id: "questions", label: "Ankieta" },
  { id: "comments", label: "Ocena i komentarze" },
];

class MoviesNew extends PureComponent {
  componentDidMount() {
    const {
      getMovie,
      match: {
        params: { id },
      },
    } = this.props;
    getMovie(id);
  }

  renderPanels = type => {
    switch (type) {
      case "basic_info":
        return <BasicData />;
      case "questions":
        return <FormQuest />;
      case "comments":
        return <Comments />;
      default:
        return <BasicData />;
    }
  };

  onSubmit = values => {
    const {
      match: {
        params: { id },
      },
      editMovie,
    } = this.props;
    editMovie(id, values);
  };

  render() {
    const { movie, history } = this.props;

    if (!movie || movie.status === "invalid" || movie.status === "loading") return <PageLoader />;

    if (movie.status === "failure") {
      return <p>Error</p>;
    }
    const {
      match: {
        params: { type },
      },
    } = this.props;

    return (
      <Main breadcrumbs={[{ name: "filmy", href: `/videos` }, { name: movie?.data?.name }]} title={movie?.data?.name}>
        <ul className="dashboard__tabs">
          {tabs.map(({ label, id }) => (
            <li
              onClick={() => history.push(`/videos/${movie.data.id}/${id}`)}
              key={id}
              className={`tab dashboard-tab-text ${type === id ? "active" : ""}`}
            >
              {label}
            </li>
          ))}
        </ul>
        {this.renderPanels(type)}
      </Main>
    );
  }
}

const mapStateToProps = ({ movie }) => ({
  movie,
});

const mapDispatchToProps = dispatch => ({
  getMovie: id => dispatch(getMovie(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MoviesNew));
