import React, {useState} from "react";
import {connect} from "react-redux";
import {Button, ModalDelete} from "components";
import {removeSectionVideo} from "actions/sections";
import {url} from 'utils/api'
const Video = ({
  name,
  preview_url,
  section_id,
  section_video_id,
  removeSectionVideo,
}) => {
  const [is_open, open] = useState(false);
  const handleDelete = () => {
    removeSectionVideo(section_id, section_video_id);
  };
  return (
    <>
      {is_open && (
        <ModalDelete
          name={name}
          handleClose={() => open(false)}
          handleDelete={handleDelete}
        />
      )}
      <div className="video">
        <div className="video__image" style={{backgroundImage: `url(${url}${preview_url})`}}>
          <Button
            type="delete"
            text="Usuń"
            onClick={() => open(true)}
            style={{padding: "2px 6px"}}
          />
        </div>
        <div className="video__title">
          <h3 className="title">{name}</h3>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  removeSectionVideo: (section_id, id) =>
    dispatch(removeSectionVideo(section_id, id)),
});

export default connect(null, mapDispatchToProps)(Video);
