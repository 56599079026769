import React from "react";

export default function Home() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <rect fill="none" width="20" height="20" />
      <path
        fill="#3699ff"
        fillRule="evenodd"
        d="M5.226,4H8.494A1.226,1.226,0,0,1,9.72,5.226v.817A1.226,1.226,0,0,1,8.494,7.269H5.226A1.226,1.226,0,0,1,4,6.043V5.226A1.226,1.226,0,0,1,5.226,4Zm7.354,9.806h3.269a1.226,1.226,0,0,1,1.226,1.226v.817a1.226,1.226,0,0,1-1.226,1.226H12.58a1.226,1.226,0,0,1-1.226-1.226v-.817A1.226,1.226,0,0,1,12.58,13.806Z"
        transform="translate(-0.731 -0.731)"
      />
      <path
        fill="#3699ff"
        fillRule="evenodd"
        opacity="0.3"
        d="M5.226,8.9H8.494A1.226,1.226,0,0,1,9.72,10.129v5.72a1.226,1.226,0,0,1-1.226,1.226H5.226A1.226,1.226,0,0,1,4,15.849v-5.72A1.226,1.226,0,0,1,5.226,8.9ZM12.58,4h3.269a1.226,1.226,0,0,1,1.226,1.226v5.72a1.226,1.226,0,0,1-1.226,1.226H12.58a1.226,1.226,0,0,1-1.226-1.226V5.226A1.226,1.226,0,0,1,12.58,4Z"
        transform="translate(-0.731 -0.731)"
      />
    </svg>
  );
}
