import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import queryString from "query-string";
import {Search, Pagination} from "components";
import {Main, PageLoader} from "components";
import ListItem from "./components/ListItem";
import {Button} from "components";
import {getActors, deleteActor} from "actions/actors";

class ActorsList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      query: queryString.parse(this.props.location.search).q,
    };
  }

  getActors = () => {
    const {
      location: {search},
      getActors,
    } = this.props;
    const {page, per_page, q} = queryString.parse(search);
    getActors(page, per_page, q);
  };

  componentDidMount() {
    this.getActors();
  }

  handleDeleteActor = (id) => {
    this.props
      .deleteActor(id)
      .then(() => {
        this.props.getActors();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const {history, location, actors, getActors} = this.props;
    const {query} = this.state;

    if (!actors || actors.status === "invalid" || actors.status === "loading")
      return <PageLoader />;

    if (actors.status === "failure") {
      return <p>Error</p>;
    }
    const {data, meta} = actors;
    return (
      <Main breadcrumbs={[{name: "Bohaterowie"}]} title="Bohaterowie">
        <div className="dashboard">
          <header className="dashboard__header">
            <div className="icon__container">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
              >
                <rect fill="none" d="M0,0H20V20H0Z" />
                <path
                  opacity="0.3"
                  fill="#494b74"
                  d="M15.833,12.167a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,15.833,12.167Zm-7.5-2.5a3.333,3.333,0,1,1,3.333-3.333A3.333,3.333,0,0,1,8.333,9.667Z"
                  transform="translate(-0.833 -0.5)"
                />
                <path
                  fill="#494b74"
                  d="M14.668,14.667c2.839.031,5.157,1.466,5.331,4.5a.425.425,0,0,1-.452.5H16.333A8.3,8.3,0,0,0,14.668,14.667ZM0,19c.323-3.977,3.551-6,7.486-6,3.99,0,7.268,1.911,7.512,6a.572.572,0,0,1-.626.667H.606A.923.923,0,0,1,0,19Z"
                  transform="translate(0 -2.167)"
                />
              </svg>
            </div>
            <h2 className="dashboard__title heading">Zarządzaj bohaterami</h2>
            <Button
              type="add"
              text="Dodaj bohatera"
              onClick={() => history.push("/heroes/new")}
            />
          </header>
          <Search
            action={(page, per_page, query, loading) => {
              this.setState({query});
              getActors(page, per_page, query, loading);
            }}
            defaultValue={query}
          />
          {data?.length > 0 ? (
            <>
              <table className="table">
                <thead className="table__header table-head">
                  <tr>
                    <th>Bohater</th>
                    <th>Kategoria</th>
                    <th>Liczba filmów</th>
                    <th width="200">Działania</th>
                  </tr>
                </thead>
                <tbody className="table__body table-body">
                  {data.map((props) => (
                    <ListItem
                      key={props.id}
                      handleDeleteActor={(id) => this.handleDeleteActor(id)}
                      {...props}
                    />
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <div style={{padding: 20}}>
              <p>Brak bohaterów</p>
            </div>
          )}
          <Pagination
            data={{...meta, query}}
            history={history}
            location={location}
            action={(page, per_page) => getActors(page, per_page, query)}
          />
        </div>
      </Main>
    );
  }
}

const mapStateToProps = ({actors}) => ({actors});

const mapDispatchToProps = (dispatch) => ({
  deleteActor: (id) => dispatch(deleteActor(id)),
  getActors: (page, per_page, query, loading) =>
    dispatch(getActors(page, per_page, query, loading)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ActorsList));
