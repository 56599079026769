import {toastr} from "react-redux-toastr";
import {client, apiFormatter} from "utils/api";

export const getSections = (
  page = 1,
  per_page = 10,
  query = null,
  loading = true
) => async (dispatch) => {
  loading && dispatch({type: "SECTIONS_LOADING"});
  await client
    .get(
      `sections?sort[order]=asc&sort[column]=id&page=${page}&per_page=${per_page}${
        query ? `&q[name_matches]=%25${query}%25` : ""
      }`
    )
    .then(({data}) => {
      dispatch({
        type: "SECTIONS_SUCCESS",
        data: apiFormatter(data),
        meta: data.meta,
      });
    })
    .catch(() => {
      dispatch({type: "SECTIONS_FAILURE"});
      toastr.error("Błąd", "Wystąpił błąd podzczas pobierania");
    });
};

export const getSection = (id) => async (dispatch) => {
  dispatch({type: "SECTION_LOADING"});
  await client
    .get(`sections/${id}?include=section_videos.video`)
    .then(({data}) => {
      dispatch({
        type: "SECTION_SUCCESS",
        data: apiFormatter(data),
      });
    })
    .catch(() => {
      dispatch({type: "SECTION_FAILURE"});
      toastr.error("Błąd", "Wystąpił błąd podzczas pobierania");
    });
};

export const deleteSection = (id, data) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/sections/${id}`);
      toastr.success("Sukces", "Sekcja została usunięta");
      resolve();
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      reject();
    }
  });

export const addSection = (data) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await client.post(`/sections`, data);
      toastr.success("Sukces", "Sekcja została dodana");
      resolve(res?.data?.data?.id);
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      reject();
    }
  });

export const editSection = (id, data) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await client.put(`/sections/${id}?include=section_videos.video`, data);
      toastr.success("Sukces", "Sekcja została zaktualizowana");
      dispatch({
        type: "SECTION_SUCCESS",
        data: apiFormatter(res.data),
      });
      resolve();
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      reject();
    }
  });

export const addSectionVideo = (section_id, data) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await client.post(
        `/sections/${section_id}/section_videos?include=section_videos.video`,
        data
      );
      toastr.success("Sukces", "Dodano film");
      dispatch({
        type: "SECTION_SUCCESS",
        data: apiFormatter(res.data),
      });
      resolve();
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      reject();
    }
  });
export const removeSectionVideo = (section_id, video_id) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await client.delete(
        `/sections/${section_id}/section_videos/${video_id}?include=section_videos.video`
      );
      toastr.success("Sukces", "Usunięto film");
      dispatch({
        type: "SECTION_SUCCESS",
        data: apiFormatter(res.data),
      });
      resolve();
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      reject();
    }
  });
