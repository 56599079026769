import React from "react";
import ActorIcon from "./ActorIcon";
import DashboardIcon from "./DashboardIcon";
import MoviesIcon from "./MoviesIcon";
import InfoIcon from "./InfoIcon";
import MovieCategoriesIcon from "./MovieCategoriesIcon";
import ActorCategoriesIcon from "./ActorCategoriesIcon";
import StaticPagesIcon from "./StaticPagesIcon";
import HomeIcon from "./HomeIcon";
import UsersIcon from "./UsersIcon";

export const renderIcons = (icon) => {
  switch (icon) {
    case "actors":
      return <ActorIcon />;
    case "dashboard":
      return <DashboardIcon />;
    case "movies":
      return <MoviesIcon />;
    case "info":
      return <InfoIcon />;
    case "movie_categories":
      return <MovieCategoriesIcon />;
    case "actor_categories":
      return <ActorCategoriesIcon />;
    case "static":
      return <StaticPagesIcon />;
    case "home":
      return <HomeIcon />;
    case "users":
      return <UsersIcon />;
    default:
      return null;
  }
};
