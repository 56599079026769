import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import queryString from "query-string";
import {Search, Pagination} from "components";
import {Main, PageLoader} from "components";
import ListItem from "./components/ListItem";
import {Button} from "components";
import {getSections, deleteSection} from "actions/sections";

class HomeList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      query: queryString.parse(this.props.location.search).q,
    };
  }

  getSections = () => {
    const {
      location: {search},
      getSections,
    } = this.props;
    const {page, per_page, q} = queryString.parse(search);
    getSections(page, per_page, q);
  };

  componentDidMount() {
    this.getSections();
  }

  handleDelete = (id) => {
    this.props
      .deleteSection(id)
      .then(() => {
        this.getSections();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const {history, location, sections, getSections} = this.props;
    const {query} = this.state;

    if (
      !sections ||
      sections.status === "invalid" ||
      sections.status === "loading"
    )
      return <PageLoader />;

    if (sections.status === "failure") {
      return <p>Error</p>;
    }
    const {data, meta} = sections;
    return (
      <Main breadcrumbs={[{name: "Strona główna"}]} title="Strona główna">
        <div className="dashboard">
          <header className="dashboard__header">
            <div className="icon__container">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
              >
                <g opacity="0.9">
                  <rect fill="none" width="20" height="20" />
                  <rect
                    fill="#3699ff"
                    width="5.833"
                    height="5.833"
                    rx="1.5"
                    transform="translate(3.333 3.333)"
                  />
                  <path
                    fill="#3699ff"
                    fillRule="evenodd"
                    opacity="0.3"
                    d="M5.25,11.5H8.583a1.25,1.25,0,0,1,1.25,1.25v3.333a1.25,1.25,0,0,1-1.25,1.25H5.25A1.25,1.25,0,0,1,4,16.083V12.75A1.25,1.25,0,0,1,5.25,11.5ZM12.75,4h3.333a1.25,1.25,0,0,1,1.25,1.25V8.583a1.25,1.25,0,0,1-1.25,1.25H12.75a1.25,1.25,0,0,1-1.25-1.25V5.25A1.25,1.25,0,0,1,12.75,4Zm0,7.5h3.333a1.25,1.25,0,0,1,1.25,1.25v3.333a1.25,1.25,0,0,1-1.25,1.25H12.75a1.25,1.25,0,0,1-1.25-1.25V12.75A1.25,1.25,0,0,1,12.75,11.5Z"
                    transform="translate(-0.667 -0.667)"
                  />
                </g>
              </svg>
            </div>
            <h2 className="dashboard__title heading">
              Zarządzanie sekcjami filmowymi
            </h2>
            <Button
              type="add"
              text="Dodaj sekcję"
              onClick={() => history.push("/home_page/new")}
            />
          </header>
          <Search
            action={(page, per_page, query, loading) => {
              this.setState({query});
              getSections(page, per_page, query, loading);
            }}
            defaultValue={query}
          />
          {data?.length > 0 ? (
            <>
              <table className="table">
                <thead className="table__header table-head">
                  <tr>
                    <th>Nazwa sekcji</th>
                    <th width="200">liczba filmów</th>
                    <th width="200">Działania</th>
                  </tr>
                </thead>
                <tbody className="table__body table-body">
                  {data.map((props) => (
                    <ListItem
                      key={props.id}
                      handleDelete={this.handleDelete}
                      {...props}
                    />
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <div style={{padding: 20}}>
              <p>Brak sekcji</p>
            </div>
          )}
          <Pagination
            data={{...meta, query}}
            history={history}
            location={location}
            action={(page, per_page) => getSections(page, per_page, query)}
          />
        </div>
      </Main>
    );
  }
}

const mapStateToProps = ({sections}) => ({sections});

const mapDispatchToProps = (dispatch) => ({
  deleteSection: (id) => dispatch(deleteSection(id)),
  getSections: (page, per_page, query, loading) =>
    dispatch(getSections(page, per_page, query, loading)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HomeList));
