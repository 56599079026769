import { toastr } from "react-redux-toastr";
import { client, apiFormatter } from "utils/api";
import { handleErrors } from "utils/helpers";

export const getMovieCategoryMovies = (page = 1, per_page = 10, category_id) => async dispatch => {
  dispatch({ type: "MOVIE_CATEGORY_MOVIES_LOADING" });
  await client
    .get(
      `videos?type=VideoCategory&sort[order]=asc&sort[column]=id&page=${page}&per_page=${per_page}&q[category_joins_category_id_eq]=${category_id}`
    )
    .then(({ data }) => {
      dispatch({
        type: "MOVIE_CATEGORY_MOVIES_SUCCESS",
        data: apiFormatter(data),
        meta: data.meta,
      });
    })
    .catch(() => {
      dispatch({ type: "MOVIE_CATEGORY_MOVIES_FAILURE" });
      toastr.error("Błąd", "Wystąpił błąd podzczas pobierania");
    });
};

export const getMoviesCategories = (page = 1, per_page = 10, query = null, loading = true) => async dispatch => {
  loading && dispatch({ type: "MOVIES_CATEGORIES_LOADING" });
  await client
    .get(
      `categories?type=VideoCategory&sort[order]=asc&sort[column]=id&page=${page}&per_page=${per_page}${
        query ? `&q[name_matches]=%25${query}%25` : ""
      }`
    )
    .then(({ data }) => {
      dispatch({
        type: "MOVIES_CATEGORIES_SUCCESS",
        data: apiFormatter(data),
        meta: data.meta,
      });
    })
    .catch(() => {
      dispatch({ type: "MOVIES_CATEGORIES_FAILURE" });
      toastr.error("Błąd", "Wystąpił błąd podzczas pobierania");
    });
};

export const getMoviesCategory = id => async dispatch => {
  dispatch({ type: "MOVIES_CATEGORY_LOADING" });
  await client
    .get(`categories/${id}?include=seo_content`)
    .then(({ data }) => {
      dispatch({
        type: "MOVIES_CATEGORY_SUCCESS",
        data: apiFormatter(data),
      });
    })
    .catch(() => {
      dispatch({ type: "MOVIES_CATEGORY_FAILURE" });
      toastr.error("Błąd", "Wystąpił błąd podzczas pobierania");
    });
};

export const deleteMoviesCategory = (id, data) => dispatch =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/categories/${id}`);
      toastr.success("Sukces", "Kategoria została usunięta");
      resolve();
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      reject();
    }
  });

export const addMoviesCategory = data => dispatch =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await client.post(`/categories`, data);
      toastr.success("Sukces", "Kategoria została dodana");
      resolve(res?.data?.data?.id);
    } catch (err) {
      console.log(err);
      toastr.error("Błąd", "Wystąpił błąd");
      reject(handleErrors(err?.response?.data?.errors));
    }
  });

export const editMoviesCategory = (id, data) => dispatch =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await client.put(`/categories/${id}?include=seo_content`, data);
      toastr.success("Sukces", "Kategoria została zaktualizowana");
      dispatch({
        type: "MOVIES_CATEGORY_SUCCESS",
        data: apiFormatter(res.data),
      });
      resolve();
    } catch (err) {
      console.log(err);
      toastr.error("Błąd", "Wystąpił błąd");
      reject(handleErrors(err?.response?.data?.errors));
    }
  });
