import React, { PureComponent } from "react";
import Button from "./Button";
import Dropzone from "react-dropzone-uploader";

class ModalUpload extends PureComponent {
  static defaultProps = {
    accept: "image/*",
  };

  state = {
    isActive: false,
    file: null,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isActive: true });
    }, 250);
  }

  handleChangeStatus = ({ file }, status) => {
    if (status === "done") {
      this.setState({ file });
    }
  };
  render() {
    const { handleClose, handleUpload, limit, headerText, accept } = this.props;
    return (
      <div className="popup-wrapper">
        <div
          className={`popup ${this.state.isActive ? "active" : ""}`}
          style={{ maxWidth: 650 }}
        >
          <header className="popup__header">
            <div className="icon__container">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
              >
                <path d="M0,0H20V20H0Z" style={{ fill: "none" }} />
                <path
                  opacity="0.3"
                  fill="#5d78ff"
                  d="M5.548,2h6.566a1.25,1.25,0,0,1,.807.3l3.97,3.358a1.25,1.25,0,0,1,.443.954V17.069c0,1.492-.017,1.6-1.548,1.6H5.548c-1.531,0-1.548-.105-1.548-1.6V3.6C4,2.105,4.017,2,5.548,2Z"
                  transform="translate(-0.667 -0.333)"
                />
                <path
                  fill="#5d78ff"
                  d="M8.868,13.064H10.5v1.673a.417.417,0,0,0,.417.417h.853a.417.417,0,0,0,.417-.417V13.064h1.63a.417.417,0,0,0,.318-.686L11.659,9.457a.417.417,0,0,0-.636,0L8.55,12.379a.417.417,0,0,0,.318.686Z"
                  transform="translate(-1.409 -1.552)"
                />
              </svg>
            </div>
            <h2 className="dashboard__title heading">{headerText}</h2>
            <Button type="close" onClick={handleClose} />
          </header>
          <section className="popup__body" style={{ padding: 40 }}>
            <Dropzone
              onChangeStatus={this.handleChangeStatus}
              SubmitButtonComponent={null}
              maxFiles={limit || 1}
              multiple={false}
              canCancel={false}
              inputContent={() => (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    style={{ marginBottom: 5 }}
                  >
                    <rect fill="none" width="24" height="24" />
                    <path
                      fill="#5d78ff"
                      opacity="0.3"
                      d="M2,13a1.075,1.075,0,0,1,1-1,1.075,1.075,0,0,1,1,1v5a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V13a1,1,0,0,1,2,0v5a4,4,0,0,1-4,4H6a4,4,0,0,1-4-4Z"
                    />
                    <rect
                      fill="#5d78ff"
                      opacity="0.3"
                      width="2"
                      height="14"
                      rx="1"
                      transform="translate(11 2)"
                    />
                    <path
                      fill="#5d78ff"
                      d="M12.036,3.378,7.707,7.707A1,1,0,0,1,6.293,6.293l5-5a1,1,0,0,1,1.376-.036l5,4.5a1,1,0,0,1-1.338,1.487Z"
                    />
                  </svg>
                  <p
                    style={{
                      fontSize: "15px",
                      lineHeight: "23px",
                      fontWeight: 300,
                      color: "#595D6E",
                    }}
                  >
                    Przenieś plik tutaj lub kliknij, aby go dodać.
                  </p>
                  <p
                    style={{
                      fontSize: "13px",
                      lineHeight: "20px",
                      fontWeight: 300,
                      color: "#595D6E",
                    }}
                  >
                    Przenieś plik tutaj lub kliknij, aby go dodać.
                  </p>
                </>
              )}
              inputWithFilesContent="Dodaj"
              styles={{
                dropzone: {
                  width: 425,
                  minHeight: 140,
                  padding: 10,
                  border: "2px dashed #EBEDF2",
                  borderRadius: 0,
                  flexDirection: "row",
                  flexFlow: "wrap",
                  justifyContent: "center",
                },
                inputLabel: {
                  flexDirection: "column",
                  justifyContent: "center",
                },
                previewImage: {
                  maxHeight: "initial",
                  maxWidth: "calc(100% - 15px)",
                },
                preview: {
                  padding: 10,
                  border: "none",
                  display: "inline-flex",
                  width: "auto",
                  alignItems: "flex-start",
                },
                inputLabelWithFiles: {
                  margin: "auto",
                  backgroundColor: "#44B2F1",
                  color: "#fff",
                  borderRadius: "5px",
                },
              }}
              accept={accept}
              disabled={(files) =>
                files.some((f) =>
                  ["preparing", "getting_upload_params", "uploading"].includes(
                    f.meta.status
                  )
                )
              }
            />
          </section>
          <div
            className="popup__footer"
            style={{
              padding: "10px 15px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button type="cancel" onClick={handleClose} />
            <Button
              type="save"
              disabled={!this.state.file}
              text="Zapisz"
              onClick={() =>
                handleUpload(this.state.file)
                  .then(() => handleClose())
                  .catch((err) => console.log(err))
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ModalUpload;
