import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button } from "components";
import ListItem from "./components/ListItem";
import { deleteQuestion } from "actions/questions";

const QuestionsList = ({ questions, deleteQuestion, history, match: { params } }) => {
  const handleDelete = id => {
    deleteQuestion(id)
      .then(() => {
        history.push(`/videos/${params.id}/questions`);
      })
      .catch(err => {
        console.log(err);
      });
  };
  return (
    <div className="dashboard">
      <header className="dashboard__header">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
          <rect fill="none" width="20" height="20" />
          <path
            fill="#3699ff"
            fillRule="evenodd"
            opacity="0.3"
            d="M7.333,3v.417a1.25,1.25,0,0,0,1.25,1.25H12.75A1.25,1.25,0,0,0,14,3.417V3h1.667a1.667,1.667,0,0,1,1.667,1.667V18a1.667,1.667,0,0,1-1.667,1.667h-10A1.667,1.667,0,0,1,4,18V4.667A1.667,1.667,0,0,1,5.667,3Z"
            transform="translate(-0.667 -0.5)"
          />
          <path
            d="M10.667,1.833a.833.833,0,0,1,1.667,0h1.25A.417.417,0,0,1,14,2.25v.833a.417.417,0,0,1-.417.417H9.417A.417.417,0,0,1,9,3.083V2.25a.417.417,0,0,1,.417-.417Z"
            transform="translate(-1.5 -0.167)"
          />
          <rect fill="#3699ff" opacity="0.3" width="5.833" height="1.667" rx="0.833" transform="translate(8.333 7.5)" />
          <rect fill="#3699ff" opacity="0.3" width="1.667" height="1.667" rx="0.833" transform="translate(5.833 7.5)" />
          <rect
            fill="#3699ff"
            opacity="0.3"
            width="1.667"
            height="1.667"
            rx="0.833"
            transform="translate(5.833 10.833)"
          />
          <rect
            fill="#3699ff"
            opacity="0.3"
            width="5.833"
            height="1.667"
            rx="0.833"
            transform="translate(8.333 10.833)"
          />
          <rect
            fill="#3699ff"
            opacity="0.3"
            width="1.667"
            height="1.667"
            rx="0.833"
            transform="translate(5.833 14.167)"
          />
          <rect
            fill="#3699ff"
            opacity="0.3"
            width="5.833"
            height="1.667"
            rx="0.833"
            transform="translate(8.333 14.167)"
          />
        </svg>
        <h2 className="dashboard__title heading">Lista pytań</h2>
        {!!params.question_id && (
          <Button type="add" text="Dodaj pytanie" onClick={() => history.push(`/videos/${params.id}/questions`)} />
        )}
      </header>
      <table className="table">
        <thead className="table__header table-head">
          <tr>
            <th>Pytanie</th>
            <th width="200">Działania</th>
          </tr>
        </thead>

        <tbody className="table__body table-body">
          {questions?.data?.length > 0 ? (
            questions.data.map(item => <ListItem key={item.id} handleDelete={handleDelete} {...item} />)
          ) : (
            <tr>
              <td>Brak</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default connect(
  ({ questions }) => ({ questions }),
  dispatch => ({
    deleteQuestion: id => dispatch(deleteQuestion(id)),
  })
)(withRouter(QuestionsList));
