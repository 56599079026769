const initialState = {
  isOpen: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SIDEBAR_TOGGLE": {
      return { isOpen: !state.isOpen };
    }
    default:
      return state;
  }
};
