import React, { useState } from 'react'
import { withRouter } from 'react-router-dom';
import { Button, ModalDelete } from 'components';

const ListItem = ({ id, name, categorizable, handleDelete, history }) => {

    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

    return (
        <>
            {isDeleteModalOpen && (
                <ModalDelete
                    handleDelete={() => handleDelete(id)}
                    handleClose={() => setDeleteModalOpen(false)}
                    name={name}
                />
            )}
            <tr key={id}>
                <td>{name}</td>
                <td>{categorizable.length}</td>
                <td>
                    <Button type="edit" onClick={() => history.push(`/movies_categories/${id}/basic_info`)} />
                    <Button type="delete-bin" onClick={() => setDeleteModalOpen(true)} />
                </td>
            </tr>
        </>
    )
}

export default withRouter(ListItem);