import {toastr} from "react-redux-toastr";
import {client, apiFormatter} from "utils/api";

export const getUsers = (
  page = 1,
  per_page = 10,
  query = null,
  loading = true
) => async (dispatch) => {
  loading && dispatch({type: "USERS_LOADING"});
  await client
    .get(
      `/users?sort[order]=asc&sort[column]=id&page=${page}&per_page=${per_page}${
        query ? `&q[name_matches]=%25${query}%25` : ""
      }`
    )
    .then(({data}) => {
      dispatch({
        type: "USERS_SUCCESS",
        data: apiFormatter(data),
        meta: data.meta,
      });
    })
    .catch(() => {
      dispatch({type: "USERS_FAILURE"});
      toastr.error("Błąd", "Wystąpił błąd podzczas pobierania");
    });
};

export const deleteUser = (id, data) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/users/${id}`);
      toastr.success("Sukces", "Użytkownik został usunięty");
      resolve();
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd. API - brak obsługi delete ");
      reject();
    }
  });

export const getUser = (id) => async (dispatch) => {
  dispatch({type: "USER_LOADING"});
  await client
    .get(`users/${id}`)
    .then(({data}) => {
      dispatch({
        type: "USER_SUCCESS",
        data: apiFormatter(data),
      });
    })
    .catch(() => {
      dispatch({type: "USER_FAILURE"});
      toastr.error("Błąd", "Wystąpił błąd podzczas pobierania");
    });
};

export const addUser = (data) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await client.post(`/users`, data);
      toastr.success("Sukces", "Użytkownik został dodany");
      resolve(res?.data?.data?.id);
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      reject();
    }
  });

export const editUser = (id, data) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const user = await client.put(`/users/${id}`, data);
      toastr.success("Sukces", "Użytkownik zostal zaktualizowany");
      dispatch({
        type: "USER_SUCCESS",
        data: apiFormatter(user.data),
      });
      resolve();
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      reject();
    }
  });
