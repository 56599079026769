import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import {Field, reduxForm} from "redux-form";
import {
  Input,
  ActionBar,
  Textarea,
  ImageUploader,
  Editor,
  AsyncSelect,
} from "components";
import {editActor} from "actions/actors";
import {url, client} from "utils/api";

const InputField = (props) => <Input {...props} />;
const TextareaField = (props) => <Textarea {...props} />;

const loadOptions = (q) => {
  return new Promise(async (resolve, reject) => {
    try {
      const categories = await client.get(
        `categories?type=ActorCategory&sort[order]=asc&sort[column]=id&page=1&per_page=10${
          q ? `&q[name_matches]=%25${q}%25` : ""
        }`
      );
      const data = categories.data.data.map(({id, attributes}) => ({
        label: attributes.name,
        value: id,
      }));
      resolve(data);
    } catch (error) {
      console.log(error);
      reject([]);
    }
  });
};

const BasiData = ({
  actor,
  editActor,
  change,
  match,
  handleSubmit,
  submiting,
  pristine,
  initialValues,
}) => {
  const handleUploadImage = (file) =>
    new Promise(async (resolve, reject) => {
      let body = new FormData();
      body.append(`actor[image]`, file);
      editActor(actor?.data?.id, body)
        .then(() => resolve())
        .catch(() => reject());
    });
  const handleDeleteImage = (file) =>
    new Promise(async (resolve, reject) => {
      let body = new FormData();
      body.append(`actor[image]`, null);
      editActor(actor?.data?.id, body)
        .then(() => resolve())
        .catch(() => reject());
    });
  const onSubmit = async ({
    name,
    intro,
    profession,
    content,
    seo_content_attributes,
    category_joins_attributes,
  }) => {
    let body = new FormData();
    // let destroy_body = new FormData();
    // destroy_body.append(`actor[category_joins_attributes][17][_destroy]`, {id: , _destroy: '1'});
    // await client.put(`/actors/${actor?.data?.id}`, destroy_body);
    body.append(`actor[name]`, name);
    body.append(`actor[intro]`, intro);
    body.append(`actor[profession]`, profession);
    body.append(`actor[content]`, content);
    // body.append(
    //   `actor[category_joins_attributes][][_destroy]`,
    //  1
    // );
    // body.append(
    //   `actor[category_joins_attributes][][id]`,
    //   category_joins_attributes?.category_id
    // );
    body.append(
      `actor[seo_content_attributes][meta_title]`,
      seo_content_attributes?.meta_title
    );
    body.append(
      `actor[seo_content_attributes][meta_description]`,
      seo_content_attributes?.meta_description
    );
    body.append(
      `actor[seo_content_attributes][slug_url]`,
      seo_content_attributes?.slug_url
    );

    editActor(actor?.data?.id, body);
  };
  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <div className="dashboard">
        <header className="dashboard__header">
          <div className="icon__container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
            >
              <rect fill="none" width="20" height="20" />
              <circle
                fill="#494b74"
                opacity="0.3"
                cx="8.333"
                cy="8.333"
                r="8.333"
                transform="translate(1.667 1.667)"
              />
              <rect
                fill="#3699ff"
                width="1.667"
                height="5.833"
                rx="0.833"
                transform="translate(9.167 8.333)"
              />
              <rect
                fill="#494b74"
                width="1.667"
                height="1.667"
                rx="0.833"
                transform="translate(9.167 5.833)"
              />
            </svg>
          </div>
          <h2 className="dashboard__title heading">Podstawowe informacje</h2>
        </header>
        <div className="dashboard__form">
          <div className="dashboard-wrapper-flex">
            <div className="column-half">
              <Field
                label="Nazwa bohatera *"
                name="name"
                component={InputField}
              />
              <Field label="Intro *" name="intro" component={TextareaField} />
              <Field
                label="Zawód/Rola/Kim był?"
                name="profession"
                component={InputField}
              />
            </div>
            <div className="column-half">
              <Field
                name="category_joins_attributes.category_id"
                component={InputField}
                type="hidden"
              />
              <AsyncSelect
                label="Wybierz kategorie"
                name="category"
                initValue={
                  actor?.data?.category_joins.length > 0
                    ? {
                        label: actor?.data?.category_joins[0]?.category?.name,
                        value: actor?.data?.category_joins[0]?.category?.id,
                      }
                    : {}
                }
                onChange={({value}) =>
                  change("category_joins_attributes.category_id", value)
                }
                getData={loadOptions}
              />
              <ImageUploader
                addText="dodaj zdjęcie"
                url={url}
                images={
                  actor?.data?.image_url ? [{path: actor.data.image_url}] : []
                }
                handleUpload={handleUploadImage}
                handleDelete={handleDeleteImage}
                limit={1}
              />
            </div>
          </div>
        </div>
      </div>
      <Editor
        label="Opis"
        name="content"
        value={initialValues.content}
        change={change}
      />
      <div className="dashboard">
        <header className="dashboard__header">
          <div className="icon__container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
            >
              <rect fill="none" width="20" height="20" />
              <circle
                fill="#494b74"
                opacity="0.3"
                cx="8.333"
                cy="8.333"
                r="8.333"
                transform="translate(1.667 1.667)"
              />
              <rect
                fill="#3699ff"
                width="1.667"
                height="5.833"
                rx="0.833"
                transform="translate(9.167 8.333)"
              />
              <rect
                fill="#494b74"
                width="1.667"
                height="1.667"
                rx="0.833"
                transform="translate(9.167 5.833)"
              />
            </svg>
          </div>
          <h2 className="dashboard__title heading">SEO</h2>
        </header>
        <div className="dashboard__form">
          <div className="form-row grid-2">
            <Field
              label="Meta title"
              name="seo_content_attributes.meta_title"
              component={InputField}
            />
            <Field
              label="Slug URL"
              name="seo_content_attributes.slug_url"
              component={InputField}
            />
          </div>
          <div className="form-row">
            <Field
              label="Meta description"
              name="seo_content_attributes.meta_description"
              component={TextareaField}
            />
          </div>
          <ActionBar
            saveAction={handleSubmit(onSubmit)}
            disableSave={pristine || submiting}
            cancel="/actors"
          />
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = ({actor}) => ({
  actor,
  initialValues: {
    name: actor?.data?.name,
    content: actor?.data?.content,
    intro: actor?.data?.intro,
    profession: actor?.data?.profession,
    seo_content_attributes: {
      meta_title: actor?.data?.seo_content?.meta_title,
      meta_description: actor?.data?.seo_content?.meta_description,
      slug_url: actor?.data?.seo_content?.slug_url,
    },
    category_joins_attributes: {
      category_id:
        actor?.data?.category_joins.length > 0
          ? actor?.data?.category_joins[0]?.category?.id
          : null,
    },
  },
});

const mapDispatchToProps = (dispatch) => ({
  editActor: (data, id) => dispatch(editActor(data, id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({form: "actor_edit", enableReinitialize: true})(
    withRouter(BasiData)
  )
);
